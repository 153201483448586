






































import { staticData } from '@/constant/config'
import { getIntroShow, setIntroShow } from '@/utils/utils'
import { Vue, Component, Prop, Model, Emit } from 'vue-property-decorator'
import Guide from './Guide.vue'
@Component({
  components: {
    Guide,
  },
})
export default class Introduction extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean

  private guideType: any = 'book'
  private guideVis: any = false

  private get intros(): any {
    return [
      {
        title: this.$t('router.studentDetail'),
        content: this.$t('intros.studentDetail'),
      },
      {
        title: this.$t('router.teaching'),
        content: this.$t('intros.teaching'),
      },
      {
        title: this.$t('router.gradeBook'),
        content: this.$t('intros.gradeBook'),
      },
      {
        title: this.$t('router.diary'),
        content: this.$t('intros.diary'),
      },
      {
        title: this.$t('router.moment'),
        content: this.$t('intros.moment'),
      },
      {
        title: this.$t('router.attendance'),
        content: this.$t('intros.attendance'),
      },
      {
        title: this.$t('router.message'),
        content: this.$t('intros.message'),
      },
      {
        title: this.$t('router.parentsMeeting'),
        content: this.$t('intros.parentsMeeting'),
        guide: () => {
          this.showGuide('book')
        },
      },
      {
        title: this.$t('router.takeLeave'),
        content: this.$t('intros.takeLeave'),
        guide: () => {
          this.showGuide('takeLeave')
        },
      },
      {
        title: this.$t('router.calendar'),
        content: this.$t('intros.calendar'),
      },
    ]
  }

  @Emit('change')
  private closeModal(): boolean {
    const introShow = getIntroShow() || {}
    setIntroShow({
      ...introShow,
      pc: true,
    })
    return false
  }

  private showGuide(type): any {
    this.guideType = type
    this.guideVis = true
  }

  private toHelp(): void {
    const url = staticData.helpDocUrl
    window.open(url)
  }
}
