import Vue from 'vue'
import Router from 'vue-router'
import Main from '@/views/Main.vue'
import Login from '@/views/Login.vue'
import ExceptionPage from '@/views/DefaultPage/ExceptionPage.vue'
import exportCurriculum from '@/views/ExportPage/ExportCurriculum.vue'

// import ResetPassword from '@/views/ResetPassword.vue'
import Store from '@/store/index'
import { i18n } from '@/i18n/i18n'
import { StudentController } from '@/services/request.service'
import {
  routerRightCode,
  kindergarten,
  all,
  allAuthStudent,
  studentStatus,
  statusList,
} from '@/constant/constant'

import {
  getToken,
  getSchoolInfo,
  setToken,
  removeToken,
  setSchoolInfo,
  getSchoolList,
  removeFirstLogin,
  setCurrentStudent,
  getCurrentStudent,
  removeSchoolInfo,
  validateVersion,
} from '@/utils/utils'
import store from '@/store/index'

Vue.use(Router)

const teaching = {
  path: 'teaching',
  name: 'teaching',
  component: () => import(/* webpackChunkName: "assignment" */ '@/views/Teaching/Teaching.vue'),
  meta: {
    routerName: 'teaching',
    rootPage: true,
    noChild: true,
    showStudents: true,
  },
  children: [
    {
      path: 'assignmentDetail/:assignId',
      name: 'assignmentDetail',
      component: () =>
        import(
          /* webpackChunkName: "assignment" */ '@/views/Teaching/components/AssignmentDetail.vue'
        ),
      meta: {
        routerName: 'assignmentDetail',
      },
    },
  ],
}

export const studentDetail = {
  path: 'studentDetail',
  name: 'studentDetail',
  component: () =>
    import(/* webpackChunkName: "studentDetail" */ '@/views/StudentDetail/StudentDetail.vue'),
  meta: {
    routerName: 'studentDetail',
    rootPage: true,
    noChild: true,
  },
}

export const studentCurriculum = {
  path: 'studentCurriculum',
  name: 'studentCurriculum',
  component: () =>
    import(
      /* webpackChunkName: "studentCurriculum" */ '@/views/StudentCurriculum/StudentCurriculum.vue'
    ),
  meta: {
    routerName: 'studentCurriculum',
    rootPage: true,
    showStudents: true,
    noChild: true,
  },
}

export const homework = {
  path: 'homework/:classType',
  name: 'homework',
  component: () => import(/* webpackChunkName: "homework" */ '@/views/HomeWork/HomeWork.vue'),
  meta: {
    routerName: 'homework',
    rootPage: true,
    noChild: true,
    showStudents: true,
  },
  children: [
    {
      path: 'homeworkDetail',
      name: 'homeworkDetail',
      component: () =>
        import(/* webpackChunkName: "homework" */ '@/views/HomeWork/HomeWorkDetail.vue'),
      meta: {
        routerName: 'homeworkDetail',
        rootPage: true,
      },
    },
  ],
}

export const diary = {
  path: 'diary',
  name: 'diary',
  component: () => import(/* webpackChunkName: "diary" */ '@/views/Diary/Diary.vue'),
  meta: {
    routerName: 'diary',
    rootPage: true,
    noChild: true,
    showStudents: true,
  },
}

export const attendance = {
  path: 'attendance',
  name: 'attendance',
  component: () => import(/* webpackChunkName: "attendance" */ '@/views/Attendance/Attendance.vue'),
  meta: {
    routerName: 'attendance',
    noChild: true,
    showStudents: true,
  },
}

export const calendar = {
  path: 'calendar',
  name: 'calendar',
  component: () => import(/* webpackChunkName: "calendar" */ '@/views/Calendar/Calendar.vue'),
  meta: {
    routerName: 'calendar',
    noChild: true,
    showStudents: true,
  },
}

export const message = {
  path: 'message',
  name: 'message',
  component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Message.vue'),
  redirect: { name: 'outbox' },
  meta: {
    routerName: 'message',
  },
  children: [
    {
      path: 'outbox',
      name: 'outbox',
      component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Outbox.vue'),
      meta: {
        routerName: 'outbox',
        rootPage: true,
        showStudents: true,
        noChild: true,
      },
      children: [
        {
          path: 'send',
          name: 'sendMessage',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Send.vue'),
          meta: {
            routerName: 'sendMessage',
          },
        },
        {
          path: 'detail/:messageId',
          name: 'outDetail',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Detail.vue'),
          meta: {
            routerName: 'sentDetail',
          },
        },
      ],
    },
    {
      path: 'inbox/:mesType',
      name: 'inbox',
      component: () => import(/* webpackChunkName: "message" */ '@/views/Message/Inbox.vue'),
      meta: {
        routerName: 'inbox',
        rootPage: true,
        showStudents: true,
        noChild: true,
      },
      children: [
        {
          path: 'reply',
          name: 'replyMessage',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Send.vue'),
          meta: {
            routerName: 'replyMessage',
          },
        },
        {
          path: 'detail/:messageId',
          name: 'inDetail',
          hidden: true,
          component: () =>
            import(/* webpackChunkName: "message" */ '@/views/Message/components/Detail.vue'),
          meta: {
            routerName: 'receivedDetail',
          },
        },
      ],
    },
  ],
}

export const addressBook = {
  path: 'addressBook',
  name: 'addressBook',
  component: () => import(/* webpackChunkName: "otherApp" */ '@/views/AddressBook/AddressBook.vue'),
  redirect: { name: 'schoolAddressBook' },
  meta: {
    routerName: 'addressBook',
    // noChild: true,
    // rootPage: true,
  },
  children: [
    {
      path: 'schoolAddressBook',
      name: 'schoolAddressBook',
      component: () =>
        import(/* webpackChunkName: "otherApp" */ '@/views/AddressBook/SchoolAddressBook.vue'),
      meta: {
        routerName: 'schoolAddressBook',
        rootPage: true,
        // showStudents: true,
        noChild: true,
      },
    },
    {
      path: 'privateAddressBook',
      name: 'privateAddressBook',
      component: () =>
        import(/* webpackChunkName: "otherApp" */ '@/views/AddressBook/PrivateAddressBook.vue'),
      meta: {
        routerName: 'privateAddressBook',
        rootPage: true,
        showStudents: true,
        noChild: true,
      },
    },
  ],
}

export const report = {
  path: 'report',
  name: 'report',
  component: () => import(/* webpackChunkName: "report" */ '@/views/Report/Report.vue'),
  meta: {
    routerName: 'report',
    rootPage: true,
    noChild: true,
  },
}

export const personal = {
  path: 'personal',
  name: 'personal',
  component: () => import(/* webpackChunkName: "personal" */ '@/views/Personal/Personal.vue'),
  hidden: true,
  meta: {
    routerName: 'personal',
    rootPage: true,
    noChild: true,
  },
}

export const parentsMeeting = {
  path: 'parentsMeeting/:viewType',
  name: 'parentsMeeting',
  component: () =>
    import(/* webpackChunkName: "parentsMeeting" */ '@/views/ParentsMeeting/ParentsMeeting.vue'),
  meta: {
    routerName: 'parentsMeeting',
    rootPage: true,
    noChild: true,
    showStudents: true,
  },
  children: [
    {
      path: 'parentsMeetingDetail',
      name: 'parentsMeetingDetail',
      component: () =>
        import(
          /* webpackChunkName: "parentsMeeting" */ '@/views/ParentsMeeting/components/MeetingDetail.vue'
        ),
      meta: {
        routerName: 'parentsMeetingDetail',
        showHeader: false,
      },
    },
  ],
}

export const moment = {
  path: 'moment',
  name: 'moment',
  component: () => import(/* webpackChunkName: "moment" */ '@/views/Moment/Moment.vue'),
  meta: {
    routerName: 'moment',
    rootPage: true,
    noChild: true,
    showStudents: true,
  },
}

export const takeLeave = {
  path: 'takeLeave',
  name: 'takeLeave',
  component: () => import(/* webpackChunkName: "takeLeave" */ '@/views/TakeLeave/TakeLeave.vue'),
  meta: {
    routerName: 'takeLeave',
    rootPage: true,
    noChild: true,
    showStudents: true,
  },
  children: [
    {
      path: 'leaveEditor',
      name: 'leaveEditor',
      component: () =>
        import(/* webpackChunkName: "takeLeave" */ '@/views/TakeLeave/components/LeaveEditor.vue'),
      meta: {
        routerName: 'addLeave',
        showHeader: false,
      },
    },
  ],
}

export const systemMessage = {
  path: 'systemMessage',
  name: 'systemMessage',
  component: () =>
    import(/* webpackChunkName: "systemMessage" */ '@/views/SystemMessage/SystemMessage.vue'),
  hidden: true,
  meta: {
    routerName: 'systemMessage',
    rootPage: true,
    noChild: true,
  },
}

export const gradeBook = {
  path: 'gradeBook',
  name: 'gradeBook',
  component: () => import(/* webpackChunkName: "gradeBook" */ '@/views/GradeBook/GradeBook.vue'),
  hidden: true,
  meta: {
    routerName: 'gradeBook',
    rootPage: true,
    showStudents: true,
    noChild: true,
  },
}

export const announcement = {
  path: 'announcement',
  name: 'announcement',
  component: () =>
    import(/* webpackChunkName: "announcement" */ '@/views/Announcement/Announcement.vue'),
  hidden: true,
  meta: {
    routerName: 'announcement',
    rootPage: true,
    showStudents: true,
    noChild: true,
  },
  children: [
    {
      path: 'announcementDetail/:id',
      name: 'announcementDetail',
      component: () =>
        import(
          /* webpackChunkName: "announcement" */ '@/views/Announcement/components/AnnouncementDetail.vue'
        ),
      meta: {
        routerName: 'announcementDetail',
        showHeader: false,
      },
    },
  ],
}

// export const phoneReport = {
//   path: 'phoneReport',
//   name: 'phoneReport',
//   component: () => import(/* webpackChunkName: "systemMessage" */ '@/views/PhoneViews/PhoneReport.vue'),
//   hidden: true,
//   meta: {
//     routerName: 'phoneReport',
//     rootPage: true,
//     showStudents: true,
//     noChild: true
//   }
// }

const defaultPage = [
  {
    path: '/404',
    name: '404',
    component: ExceptionPage,
    props: {
      type: '404',
    },
  },
  {
    path: '/403',
    name: '403',
    component: ExceptionPage,
    props: {
      type: '403',
    },
  },
  {
    path: '/phoneWarning',
    name: 'phoneWarning',
    component: ExceptionPage,
    props: {
      type: 'phoneWarning',
    },
  },
  {
    path: '/redirect',
    name: 'redirect',
    redirect: to => {
      const auths = Store.state.routeAuths
      return calcRedirectRoute(auths)
    },
  },
]

const exportPage = [
  {
    path:
      '/exportReport/:token/:schoolId/:studentId/:periodId/:reportType/:campusType/:time/:schoolYearType',
    name: 'exportReport',
    component: () =>
      import(/* webpackChunkName: "exportPage" */ '@/views/ExportPage/ExportReport.vue'),
  },
  {
    path: '/exportMonthlyReport/:token/:schoolId/:studentId/:periodId',
    name: 'exportMonthlyReport',
    component: () =>
      import(/* webpackChunkName: "exportPage" */ '@/views/ExportPage/ExportMonthlyReport.vue'),
  },
  {
    path: '/exportCurriculum/:token/:schoolId/:time/:studentId/:key',
    name: 'exportCurriculum',
    component: exportCurriculum,
  },
]

// const previewPage = [
//   {
//     path: '/previewReport/:periodId/:type/:id',
//     name: 'previewReport',
//     component: () => import(/* webpackChunkName: "previewPage" */ '@/views/PreviewPages/PreviewReport.vue'),
//   }
// ]

function calcRedirectRoute(auths): any {
  let name
  for (let i = 0; i < auths.length; i++) {
    if (auths[i]) {
      name = auths[i]
      break
    }
  }
  if (!name) {
    name = '403'
  }
  return { name }
}

const routes = [
  {
    path: '/',
    redirect: to => {
      const auths = Store.state.routeAuths
      return calcRedirectRoute(auths)
    },
    name: 'main',
    component: Main,
    children: [
      announcement,
      studentDetail,
      studentCurriculum,
      message,
      diary,
      teaching,
      gradeBook,
      attendance,
      calendar,
      personal,
      parentsMeeting,
      takeLeave,
      // moment,
      systemMessage,
      addressBook,
      report,
    ],
  },
]
Store.commit('setRoutes', routes)
const constantRouter = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  ...defaultPage,
  ...exportPage,
  ...routes,
]

const router = new Router({
  mode: 'history',
  routes: constantRouter,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: to.meta.savedPosition || 0 }
    }
  },
}) as any

function syncRoute(): Promise<any> {
  let routeAuth = {} as any
  return new Promise(resolve => {
    StudentController.getList().then(res => {
      let students =
        res.data.map(student => ({
          ...student,
          fullName: `${student.lastName} ${student.enName} ${student.firstName} ${student.name}`,
        })) || []
      if (!students.length) {
        removeToken()
        removeSchoolInfo()
        Store.commit('changeForceBack', true)
        Store.dispatch('refreshRoutes')
        resolve('logout')
      }
      let schoolId: any = (getSchoolInfo() || {}).schoolId.toString()
      schoolId = Object.keys(statusList).includes(schoolId) ? schoolId : 'default'

      let lastStudentId = Number(getCurrentStudent())
      let currentStudent =
        students.find(item => item.studentId === lastStudentId) || students[0] || {}

      let allFlag =
        currentStudent.campusType !== '1230' || allAuthStudent.includes(currentStudent.studentId)

      let allAuthCode = [...new Set(allFlag ? all : kindergarten)].filter(item =>
        statusList[schoolId][currentStudent.status].includes(item)
      )
      routeAuth = allAuthCode
        .map(item => {
          if (typeof routerRightCode[item] !== 'object') return routerRightCode[item]
          const { name, children } = routerRightCode[item]
          return [name].concat(children || [])
        })
        .flat()
        .filter(item => item)

      Store.commit('setCurrentStudent', currentStudent)
      setCurrentStudent(currentStudent?.studentId)
      Store.commit('setRouteAuths', routeAuth)
      Store.commit('setAuthLoadStatus', true)
      Store.commit('setStudents', students)
      resolve('success')
    })
  })
}

router.beforeEach(async (to, from, next) => {
  Store.commit('changeForceBack', false)
  if (exportList.includes(to.name)) {
    if (!to.params.token) {
      next({ name: 'login' })
    } else {
      setToken(to.params.token as string, false)
      setSchoolInfo({ schoolId: to.params.schoolId })
      next()
    }
    return
  }
  let routeAuth = Store.state.routeAuths
  let authLoaded = Store.state.authLoaded
  const schoolList = getSchoolList()
  let schoolInfo: any
  if (schoolList && schoolList.length) {
    const host = window.location.host
    schoolInfo = getSchoolList().filter(item => item.domain === host)[0]
    if (!schoolInfo && process.env.VUE_APP_ENV === 'DEV') {
      schoolInfo = getSchoolList().find(item => item.schoolId === 8)
    }
    setSchoolInfo(schoolInfo || {})
  }
  if (to.name === '404') {
    next()
  } else if (!schoolInfo) {
    next({ name: '404', query: { message: i18n.tc('tips.nonExistSchool') } })
  } else if (to.name === 'login') {
    // next()
    if (getToken()) {
      next({ name: 'main' })
    } else {
      removeToken()
      removeFirstLogin()
      next()
    }
  } else if (!getToken()) {
    next({ name: 'login' })
  } else if (!authLoaded) {
    const result = await syncRoute()
    if (result === 'logout') {
      next({ name: 'login' })
      return
    }
    let el = document.getElementById('project-loading') as any
    if (el) el.remove()
    next(
      to.name === '403'
        ? { name: 'main' }
        : {
            ...to,
          }
    )
  } else if (
    whiteList.includes(to.name) ||
    previewList.includes(to.name) ||
    routeAuth.includes(to.name)
  ) {
    validateVersion(false, () => {
      next()
    })
  } else if (to.matched.length === 0) {
    next({ name: '404' })
  } else {
    // 没有权限
    const isPc = store.state.isPc
    const warningMes = i18n.t('tips.pageWarning', { function: i18n.tc(`router.${to.name}`) })
    next({ name: isPc ? '403' : 'phoneWarning', query: { title: warningMes } })
  }
})

export const whiteList = [
  'personal',
  'systemMessage',
  'login',
  '404',
  '403',
  'phoneWarning',
  // 'redirect'
]

const exportList = ['exportReport', 'exportCurriculum', 'exportMonthlyReport']

const previewList = ['previewReport']

export default router
