import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './modules/types'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    locale: 'zh',
    username: '',
    memberId: 0,
    fileUploadProgress: {},
    forceBack: false,
    auths: [],
    routes: [],
    currentStudent: {},
    unreadData: {},
    unReadTeach: {},
    systemMessageNum: {},
    routeAuths: [],
    authLoaded: false,
    students: [],
    currentSchoolYear: {},
    isPc: true,
    userInfo: {},
    operationAuths: [],
    appId: {},
    ratio: 1,
    messageIds: [],
    unreadSysData: {},
    refresh: false,
    selectedCourses: {},
    enrollmentResult: {},
  },
  getters: {
    getProgress: state => {
      return state.fileUploadProgress
    },
  },
  mutations: {
    setAllMessageIds(state, ids) {
      state.messageIds = ids
    },
    changeLocale(state, payload: string) {
      state.locale = payload
    },
    setUserInfo(
      state,
      payload: {
        username: string
        memberId: number
        classTeacher: boolean
        courseTeacher: boolean
        houseTeacher: boolean
        sectionTeacher: boolean
        isTeacher: boolean
        teacherEnName: string
        teacherName: string
      }
    ) {
      const {
        username,
        memberId,
        classTeacher,
        courseTeacher,
        houseTeacher,
        sectionTeacher,
      } = payload
      state.userInfo = payload
      state.username = username
      state.memberId = memberId
    },
    setFileUploadProgress(state, payload: { key: string; progress: number }) {
      const { key, progress } = payload
      Vue.set(state.fileUploadProgress, key, progress)
    },
    setRatio(state, ratio) {
      state.ratio = ratio
    },
    setOperationAuths(state, payload) {
      state.operationAuths = payload
    },
    changeForceBack(state, payload: boolean) {
      state.forceBack = payload
    },
    setAuths(state, payload) {
      state.auths = payload
    },
    setRouteAuths(state, payload) {
      state.routeAuths = payload
    },
    setAuthLoadStatus(state, status) {
      state.authLoaded = status
    },
    setRoutes(state, payload) {
      state.routes = payload
    },
    removeAuths(state) {
      state.auths = []
    },
    setCurrentStudent(state, payload) {
      state.currentStudent = payload
    },
    setUnreadData(state, data) {
      state.unreadData = data
    },
    setUnReadTeach(state, data) {
      state.unReadTeach = data
    },
    setUnreadSysData(state, data) {
      state.unreadSysData = data
    },
    setSystemMessageNum(state, systemMessageNum) {
      state.systemMessageNum = systemMessageNum
    },
    setStudents(state, students) {
      state.students = students
    },
    setCurrentSchoolYear(state, payload) {
      state.currentSchoolYear = payload
    },
    setIsPc(state, isPc) {
      state.isPc = isPc
    },
    initAppId(state, appId) {
      state.appId = appId
    },
    refresh(state) {
      state.refresh = true
    },
    cleanRefresh(state) {
      state.refresh = false
    },
    setSelectedCourse(state, payload) {
      state.selectedCourses = payload
    },
    setEnrollmentResult(state, payload) {
      state.enrollmentResult = payload
    },
  },
  actions: {
    refreshRoutes({ commit }) {
      commit('removeAuths')
      commit('setRouteAuths', [])
      commit('setAuthLoadStatus', false)
    },
  },
}

export default new Vuex.Store<RootState>(store)
