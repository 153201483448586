







































import { getGuideViewStatus, setGuideViewStatus, getIntroShow, setIntroShow } from '@/utils/utils'
import { BrowserBackend } from '@sentry/browser/dist/backend'
import { Vue, Component, Prop, Emit, Model } from 'vue-property-decorator'

@Component
export default class Guide extends Vue {
  @Model('change', { type: Boolean }) private readonly visible!: boolean
  @Prop() private readonly type!: any
  private current: any = 1

  private get stepMax(): any {
    switch (this.type) {
      case 'book':
        return 6
      case 'takeLeave':
        return 5
      case 'introduction':
        return 7
      default:
        return 0
    }
  }

  private get locale(): string {
    return this.$store.state.locale === 'zh' ? 'zh' : 'en'
  }

  private get ratio(): any {
    return this.$store.state.ratio
  }

  private next(): void {
    if (this.current < this.stepMax) {
      this.current += 1
    } else {
      let status: any = getGuideViewStatus()
      status[this.type + 'PC'] = true
      setGuideViewStatus(status)
      this.closeModal()
    }
  }

  private last(): void {
    if (this.current > 1) this.current -= 1
  }

  private skip(): void {
    let status: any = getGuideViewStatus()
    status[this.type + 'PC'] = true
    setGuideViewStatus(status)
    const introShow = getIntroShow() || {}
    setIntroShow({
      ...introShow,
      pc: true,
    })
    this.closeModal()
  }

  @Emit('change')
  private closeModal(): boolean {
    this.current = 1
    return false
  }
}
