













import { Component, Provide, Vue } from 'vue-property-decorator'
import handleDeviceZoom from '@/utils/handleDeviceZoom.js'

const zhCN = require('ant-design-vue/lib/locale-provider/zh_CN')
const enUS = require('ant-design-vue/lib/locale-provider/en_US')

import moment from 'moment'
import { LoginController } from '@/services/request.service'
import { validateVersion } from './utils/utils'
import { usePush } from 'vue-baidu-analytics'

require('moment/locale/zh-cn')
moment.locale('zh-cn')

@Component
export default class App extends Vue {
  @Provide() public reloadPage = this.reload
  @Provide() public baidu = usePush()

  public isRouterAlive = true

  private get locale(): object {
    const locale = this.$store.state.locale
    if (locale === 'zh') {
      moment.locale('zh-cn')
      return zhCN.default
    } else {
      moment.locale('en')
      return enUS.default
    }
  }

  private get isPc(): void {
    return this.$store.state.isPc
  }

  public reload(): void {
    this.isRouterAlive = false
    this.$nextTick(() => {
      this.isRouterAlive = true
    })
  }

  private created(): void {
    // validateVersion(true)
    new handleDeviceZoom().init()
    if (document.hidden !== undefined) {
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
          validateVersion(true)
        }
      })
    }
  }
}
