import { dateTimeFormats } from './dateTimeFormats'
import zh from './zh'
import en from './en'
import enGB from './en-GB'
import VueI18n from 'vue-i18n'
import store from '@/store/index'
import Vue from 'vue'

let locale = store.state.locale
Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale,
  dateTimeFormats,
  fallbackLocale: 'zh',
  silentTranslationWarn: true,
  messages: {
    zh,
    en,
    enGB,
  },
})
