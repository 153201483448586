/* eslint-disable */
import fetch from '../utils/fetch';
import * as M from './__model';
import {AxiosRequestConfig} from 'axios';
export class ParentController {

    /**
      * 新增家长 
      */ 
    public static add(request: M.ParentWithStudentsRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/parent/add`,request, config);
    }

    /**
      * 查询家长详情 
      */ 
    public static detail(parentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ParentDetailResponse>(`/parent/detail`, { params:{parentId},...config});
    }

    /**
      * 禁用账号 
      */ 
    public static disable(request: M.ParentIdRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/parent/disable`,request, config);
    }

    /**
      * 启用账号 
      */ 
    public static enable(request: M.ParentIdRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/parent/enable`,request, config);
    }

    /**
      * 根据手机号查询家长信息 
      */ 
    public static getParentByMobile(mobile: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.ParentResponse>(`/parent/getParentByMobile`, { params:{mobile},...config});
    }

    /**
      * 获取家属信息 
      */ 
    public static getRelatedParents(config?: AxiosRequestConfig ) {
      return fetch.get<M.ParentPageResponse[]>(`/parent/relatedParents`,config);
    }

    /**
      * 获取用户详情 
      */ 
    public static getUserInfo(config?: AxiosRequestConfig ) {
      return fetch.get<M.ParentResponse>(`/parent/userInfo`,config);
    }

    /**
      * 修改家长 
      */ 
    public static update(request: M.ParentWithStudentsRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/parent/update`,request, config);
    }

    /**
      * 修改邮箱 
      */ 
    public static updateEmail(request: M.EmailRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ResultResponse>(`/parent/updateEmail`,request, config);
    }

    /**
      * 修改手机号 
      */ 
    public static updateMobile(request: M.MobileRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ResultResponse>(`/parent/updateMobile`,request, config);
    }

    /**
      * 个人中心-修改密码 
      */ 
    public static updatePassword(request: M.PasswordRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ResultResponse>(`/parent/updatePassword`,request, config);
    }

    /**
      * 更新用户详情 
      */ 
    public static updateUserInfo(request: M.ParentRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/parent/updateUserInfo`,request, config);
    }

    /**
      * 邮箱校验 
      */ 
    public static verifyEmail(email: string, parentId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<boolean>(`/parent/verify/email`, { params:{email,parentId},...config});
    }

    /**
      * 手机号校验 
      */ 
    public static verifyMobile(mobile: string, parentId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<boolean>(`/parent/verify/mobile`, { params:{mobile,parentId},...config});
    }


}
export class GraduationController {

    /**
      * 获取毕业标准详情 
      */ 
    public static graduationDetail(settingId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GraduationStandardResponse>(`/${settingId}`, { params:{},...config});
    }

    /**
      * 毕业进度 
      */ 
    public static graduationSchedule(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ScheduleResponse>(`/schedule/${studentId}`, { params:{},...config});
    }

    /**
      * 查询学生毕业标准信息 
      */ 
    public static studentStandard(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentStandardInfoResponse>(`/standard/${studentId}`, { params:{},...config});
    }


}
export class TaskController {

    /**
      * 获取作业详情 
      */ 
    public static detail(taskStudentId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TaskResponse>(`/task/detail`, { params:{taskStudentId,studentId},...config});
    }

    /**
      * 获取任务列表 
      */ 
    public static getList(request: M.TaskPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.TaskPageResponse>>(`/task/list`, { params:request,...config});
    }

    /**
      * 获取教学课程信息列表 
      */ 
    public static getMergeList(request: M.TeachingPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.TeachingPageResponse>>(`/task/mergeList`, { params:request,...config});
    }

    /**
      * 获取进行中的考勤列表 
      */ 
    public static getProcessingTasks(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTaskResponse[]>(`/task/processing`, { params:{studentId},...config});
    }


}
export class TeacherController {

    /**
      * 查询学生关联教师 
      */ 
    public static getRelatedTeachers(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TeacherRelationResponse[]>(`/teacher/relatedTeachers`, { params:{studentId},...config});
    }


}
export class HonorsAndLeaderShipController {

    /**
      * 获取honor详情 
      */ 
    public static getHonor(honorId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.HonorResponse>(`/honors-and-leadership/honor`, { params:{honorId},...config});
    }

    /**
      * 获取honor类型下拉 
      */ 
    public static getHonorTypes(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/honors-and-leadership/honor/type`,config);
    }

    /**
      * 获取honor列表 
      */ 
    public static getHonors(studentId: number, pageCurrent: number, pageSize: number, honorTypeId?: number, sectionId?: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.HonorItemResponse>>(`/honors-and-leadership/honors`, { params:{studentId,pageCurrent,pageSize,honorTypeId,sectionId,start,end},...config});
    }

    /**
      * 获取leadership详情 
      */ 
    public static getLeaderShip(leadershipId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.LeaderShipResponse>(`/honors-and-leadership/leadership`, { params:{leadershipId},...config});
    }

    /**
      * 获取leadership列表 
      */ 
    public static getLeaderShipByStudent(studentId: number, pageCurrent: number, pageSize: number, type?: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.LeaderShipItemResponse>>(`/honors-and-leadership/leaderships`, { params:{studentId,pageCurrent,pageSize,type,start,end},...config});
    }

    /**
      * 获取leadership类型 
      */ 
    public static getLeadershipTypes(config?: AxiosRequestConfig ) {
      return fetch.get<M.CascadeResponse[]>(`/honors-and-leadership/leadership/type`,config);
    }


}
export class MeetingsController {

    /**
      * 取消预约 
      */ 
    public static disappoint(appointeeId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/meetings/appointment`, { params:{appointeeId,studentId},...config});
    }

    /**
      * 获取已预约列表 
      */ 
    public static getAppointed(studentId: number, pageCurrent: number, pageSize: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.AppointedResponse>>(`/meetings/appointed`, { params:{studentId,pageCurrent,pageSize},...config});
    }

    /**
      * 获取家长会详情 
      */ 
    public static getMeeting(meetingId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MeetingResponse>(`/meetings`, { params:{meetingId,studentId},...config});
    }

    /**
      * 获取家长会列表 
      */ 
    public static getMeetings(pageCurrent: number, pageSize: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.MeetingItemResponse>>(`/meetings/list`, { params:{pageCurrent,pageSize,studentId},...config});
    }

    /**
      * 预约老师 
      */ 
    public static makeAppointment(request: M.AppointmentRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.AppointmentResultResponse[]>(`/meetings/appointment`,request, config);
    }


}
export class CommonController {

    /**
      * 得到七牛Token 
      */ 
    public static getQiniuEtcToken(config?: AxiosRequestConfig ) {
      return fetch.get<M.TokenResponse>(`/common/qiniuToken`,config);
    }

    /**
      * 得到七牛视频转码Token 
      */ 
    public static getQiniuForVideo(config?: AxiosRequestConfig ) {
      return fetch.get<M.TokenResponse>(`/common/qiniuTokenForVideo`,config);
    }

    /**
      * 获取公众号签名 
      */ 
    public static getSign(url: string, useTestSchool: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.WechaSignResponse>(`/common/sign`, { params:{url,useTestSchool},...config});
    }

    /**
      * 更新微信openId 
      */ 
    public static refreshWxAccount(code: string, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/common/wx`,code, config);
    }

    /**
      * 保存资源信息 
      */ 
    public static saveResource(request: M.ResourceRequest, config?: AxiosRequestConfig ) {
      return fetch.post<number>(`/common/saveResource`,request, config);
    }

    /**
      * 通用翻译接口 
      */ 
    public static translate(src: string, config?: AxiosRequestConfig ) {
      return fetch.post<M.StringResponse>(`/common/translate`,src, config);
    }


}
export class DiaryController {

    /**
      * 获取日记簿记录详情 
      */ 
    public static getDiaryEntry(diaryEntryId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DiaryEntryResponse>(`/diary/`, { params:{diaryEntryId},...config});
    }

    /**
      * 获取学生日记簿详情 
      */ 
    public static getDiaryEntrySummary(pageCurrent: number, pageSize: number, studentId: number, primaryTypeId?: number, diaryEntryTypeId?: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DiaryEntryItemResponse>>(`/diary/by-student`, { params:{pageCurrent,pageSize,studentId,primaryTypeId,diaryEntryTypeId,start,end},...config});
    }

    /**
      * 获取日记簿子类型 
      */ 
    public static getDiaryEntryTypes(primaryTypeId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/diary/entry-type`, { params:{primaryTypeId},...config});
    }

    /**
      * 获取日记簿记录类型 
      */ 
    public static getPrimaryTypes(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/diary/primary-type`,config);
    }

    /**
      * 获取日记簿学生汇总列表 
      */ 
    public static getStudentDiaryInfo(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDiaryInfoResponse>(`/diary/student-info`, { params:{studentId},...config});
    }


}
export class LoginController {

    /**
      * 首次登录接口 
      */ 
    public static firstLogin(request: M.LoginRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.LoginResponse>(`/login/firstLogin`,request, config);
    }

    /**
      * 获取所有学校列表 
      */ 
    public static getAllSchools(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolBriefResponse[]>(`/login/schools`,config);
    }

    /**
      * 根据域名查询学校信息 
      */ 
    public static getSchoolInfo(baseUrl: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolBriefResponse>(`/login/schoolInfo`, { params:{baseUrl},...config});
    }

    /**
      * 登录接口 
      */ 
    public static login(request: M.LoginRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.LoginResponse>(`/login/`,request, config);
    }

    /**
      * 忘记密码-重置密码 
      */ 
    public static resetPassword(request: M.ForgotPasswordRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.LoginResponse>(`/login/resetPassword`,request, config);
    }

    /**
      * 发送验证码 
      */ 
    public static sendMobileVerifyCode(areaCode: string, mobile: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.ResultResponse>(`/login/vcodeMobileSend`, { params:{areaCode,mobile},...config});
    }

    /**
      * 发送验证码 
      */ 
    public static sendVerifyCode(account: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.ResultResponse>(`/login/vcodeSend`, { params:{account},...config});
    }

    /**
      * 发送验证码, 不校验账号是否已存在 
      */ 
    public static sendVerifyCodeNoCheck(mobile: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.ResultResponse>(`/login/vcodeSendNoCheck`, { params:{mobile},...config});
    }

    /**
      * 发送验证码到邮箱, 不校验账号是否已存在 
      */ 
    public static sendVerifyToMailCodeNoCheck(email: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.ResultResponse>(`/login/vcodeSendToMailNoCheck`, { params:{email},...config});
    }

    /**
      * 统一登录接口 
      */ 
    public static unifyLogin(request: M.UnifyLoginRequest, config?: AxiosRequestConfig ) {
      return fetch.post<M.LoginResponse>(`/login/unify`,request, config);
    }


}
export class NoticeController {

    /**
      * 活动报名 
      */ 
    public static activityApply(request: M.NoticeRegisterRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/notice/register/apply`,request, config);
    }

    /**
      * 报名自动填充信息 
      */ 
    public static applyPreviewInfo(noticeId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentInfoResponse[]>(`/notice/register/preview-info`, { params:{noticeId},...config});
    }

    /**
      * 报名结果 
      */ 
    public static applyResult(noticeId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.RegisterResultResponse>(`/notice/register/result`, { params:{noticeId,studentId},...config});
    }

    /**
      * 部门下拉框数据 
      */ 
    public static departsDropDown(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/notice/depart`,config);
    }

    /**
      * 获取公告详情 
      */ 
    public static getNotice(noticeId: number, studentId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.NoticeResponse>(`/notice/${noticeId}`, { params:{studentId},...config});
    }

    /**
      * 校历公告活动 
      */ 
    public static getNoticeCalendar(query: M.NoticeCalendarQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.NoticeCalendarResponse[]>(`/notice/calendar`, { params:query,...config});
    }

    /**
      * 个人公告列表接口 
      */ 
    public static getNoticePersonal(query: M.NoticeQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ReadInfoNoticeItemResponse>>(`/notice/personal`, { params:query,...config});
    }

    /**
      * 首页推荐 
      */ 
    public static getNoticeRecommend(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ReadInfoNoticeItemResponse[]>(`/notice/recommend`, { params:{studentId},...config});
    }

    /**
      * 获取预览公告详情 
      */ 
    public static getPreviewNotice(previewNoticeId: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.NoticeResponse>(`/notice/preview/detail`, { params:{previewNoticeId},...config});
    }

    /**
      * 更新分享计数 
      */ 
    public static getShareNotice(noticeId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/notice/increase-share`, {}, { params:{noticeId, },...config});
    }

    /**
      * 活动公告点赞 
      */ 
    public static noticeFavor(noticeId: number, favor: boolean, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/notice/favor`, {}, { params:{noticeId, favor, },...config});
    }

    /**
      * 设置已读 
      */ 
    public static setRead(noticeId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/notice/${noticeId}/read`, {}, { params:{},...config});
    }

    /**
      * 分享公告 
      */ 
    public static shareNotice(encryptionId: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.NoticeResponse>(`/notice/share`, { params:{encryptionId},...config});
    }

    /**
      * tag下拉 
      */ 
    public static tagDropdown(noticeType?: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/notice/tag`, { params:{noticeType},...config});
    }


}
export class StudentController {

    /**
      * 班级信息 
      */ 
    public static classInfo(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassInfoResponse[]>(`/student/class-info`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 查询学生详细信息 
      */ 
    public static detail(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDetailResponse>(`/student/detail`, { params:{studentId},...config});
    }

    /**
      * 查询学生课程信息信息 
      */ 
    public static getCourseInfo(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentCourseResponse[]>(`/student/course-info`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 查询学生列表 
      */ 
    public static getList(config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentBriefResponse[]>(`/student/list`,config);
    }

    /**
      * 查询学生家长账户信息 
      */ 
    public static getParents(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ParentResponse[]>(`/student/getParents`, { params:{studentId},...config});
    }

    /**
      * 查询学生说明信息 
      */ 
    public static getRemarks(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentRemarkResponse>(`/student/getRemarks`, { params:{studentId},...config});
    }

    /**
      * 获取学生简要信息 
      */ 
    public static getStudentBriefInfo(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentBriefDetailResponse>(`/student/studentBriefInfo`, { params:{studentId},...config});
    }

    /**
      * 查询关联学生列表 
      */ 
    public static getStudentList(excludeOut?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentBriefResponse[]>(`/student/studentList`, { params:{excludeOut},...config});
    }

    /**
      * 获取转学信息 
      */ 
    public static getStudentTransInfo(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTransInfoResponse>(`/student/trans-info/${studentId}`, { params:{},...config});
    }

    /**
      * 获取转学信息(课程) 
      */ 
    public static getStudentTransOutCourseInfo(studentId: number, schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTransOutCourseInfoResponse[]>(`/student/trans-info/${studentId}/course/${schoolYearId}`, { params:{},...config});
    }

    /**
      * 查询学生教师信息 
      */ 
    public static getTeachersByStudent(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.TeachersAndTypeResponse[]>(`/student/teachers`, { params:{studentId},...config});
    }

    /**
      * 更新学生头像 
      */ 
    public static updateAvatarUrl(request: M.StudentAvatarRequest, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/student/updateAvatarUrl`,request, config);
    }


}
export class AttendanceController {

    /**
      * 获取申请列表 
      */ 
    public static getApplicationList(pageCurrent: number, pageSize: number, studentId: number, startTime?: number, endTime?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.LeaveApplicationItemResponse>>(`/attendance/leave-application`, { params:{pageCurrent,pageSize,studentId,startTime,endTime},...config});
    }

    /**
      * 获取考勤类型 
      */ 
    public static getAttendanceType(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StringResponse>(`/attendance/attendance-type`, { params:{studentId},...config});
    }

    /**
      * 获取请假审批人 
      */ 
    public static getAuditors(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/attendance/leave-application/auditor`, { params:{studentId},...config});
    }

    /**
      * 每日考勤详情 
      */ 
    public static getDailyStudentAttendanceInfo(date: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DailyAttendanceResponse[]>(`/attendance/student/daily`, { params:{date,studentId},...config});
    }

    /**
      * 学生个人考勤(小学) 
      */ 
    public static getEleStudentStatistics(schoolYearId: number, start: number, end: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.EleDailyAttendanceResponse[]>(`/attendance/statistic/student/ele/${schoolYearId}`, { params:{start,end,studentId},...config});
    }

    /**
      * 幼儿园获取学生每月考勤数据 
      */ 
    public static getKGStudentAttendance(studentId: number, date: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/attendance/kg/student`, { params:{studentId,date},...config});
    }

    /**
      * 幼儿园获取学生周考勤数据 
      */ 
    public static getKGStudentAttendanceOfWeek(studentId: number, startDate: number, endDate: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/attendance/kg/student-week`, { params:{studentId,startDate,endDate},...config});
    }

    /**
      * 获取请假审批详情 
      */ 
    public static getLeaveApplication(leaveApplicationId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.LeaveApplicationResponse>(`/attendance/leave-application/detail`, { params:{leaveApplicationId},...config});
    }

    /**
      * 课节考勤统计 
      */ 
    public static getStudentCourseStatistics(start: number, end: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StatisticInfoResponse>(`/attendance/statistic/course/student`, { params:{start,end,studentId},...config});
    }

    /**
      * 学生个人考勤 
      */ 
    public static getStudentStatistics(schoolYearId: number, studentId: number, start: number, end: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentDailyAttendanceResponse[]>(`/attendance/statistic/student/${schoolYearId}`, { params:{studentId,start,end},...config});
    }

    /**
      * 检查时间是否合理 
      */ 
    public static isValid(request: M.LeaveTimeCheckRequest, config?: AxiosRequestConfig ) {
      return fetch.post<boolean>(`/attendance/leave-application/time-check`,request, config);
    }

    /**
      * 撤销申请 
      */ 
    public static retrieveApplication(leaveApplicationId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/attendance/leave-application/retrieve`, {}, { params:{leaveApplicationId, },...config});
    }

    /**
      * 创建新申请 
      */ 
    public static saveLeaveApplication(request: M.LeaveApplicationRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/attendance/leave-application`,request, config);
    }

    /**
      * 学生缺席通知列表 
      */ 
    public static studentAbsent(schoolId: number, type: string, config?: AxiosRequestConfig ) {
      return fetch.get<void>(`/attendance/student/absent`, { params:{schoolId,type},...config});
    }


}
export class CalendarController {

    /**
      * 根据月份查询校历 
      */ 
    public static getCalendarByMonth(dateStart: number, dateEnd: number, date: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CalendarResponse>(`/calendar/byMonth`, { params:{dateStart,dateEnd,date,studentId},...config});
    }

    /**
      * 查询事件信息 
      */ 
    public static getEventInfo(eventId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.EventResponse>(`/calendar/getEventInfo`, { params:{eventId},...config});
    }


}
export class GradeController {

    /**
      * 全部报告接口 
      */ 
    public static getAllGradeReports(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearReportResponse[]>(`/grade/grade-report/by-student/all`, { params:{studentId},...config});
    }

    /**
      * CD全部报告接口 
      */ 
    public static getAllGradeReportsByCD(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearReportResponse[]>(`/grade/grade-report/by-studentByCD/all`, { params:{studentId},...config});
    }

    /**
      * 查询评分标准详情 
      */ 
    public static getGradeLevelDetail(type: string, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeLevelResponse[]>(`/grade/gradeLevel/detail`, { params:{type},...config});
    }

    /**
      * 获取评估报告详情 
      */ 
    public static getGradeReportDetail(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeReportResponse>(`/grade/grade-report`, { params:{studentId,gradePeriodId},...config});
    }

    /**
      * 获取学生评估数据列表 
      */ 
    public static getGradeReports(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeReportItemResponse[]>(`/grade/grade-report/by-student`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 初中年度报告 
      */ 
    public static getMidSchoolAnnualReport(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MidSchoolAnnualReportResponse>(`/grade/annual-report/mid`, { params:{studentId,gradePeriodId},...config});
    }

    /**
      * 初中报告详情 
      */ 
    public static getMidSchoolReport(gradePeriodId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MidSchoolReportDetailResponse>(`/grade/mid-school/grade-report/detail`, { params:{gradePeriodId,studentId},...config});
    }

    /**
      * 获取报告详情 
      */ 
    public static getMonthlyReport(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyReportDetailResponse>(`/grade/report/${gradePeriodId}/${studentId}`, { params:{},...config});
    }

    /**
      * 获取发送的报告 
      */ 
    public static getMonthlyReportsByStudent(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MonthlyReportResponse[]>(`/grade/monthly-grade/by-student`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 高中年度报告 
      */ 
    public static getUpperSchoolAnnualReport(studentId: number, gradePeriodId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.UpperSchoolAnnualReportResponse>(`/grade/annual-report/upper`, { params:{studentId,gradePeriodId},...config});
    }

    /**
      * 高中报告详情 
      */ 
    public static getUpperSchoolReport(gradePeriodId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.UpperSchoolReportDetailResponse>(`/grade/upper-school/grade-report/detail`, { params:{gradePeriodId,studentId},...config});
    }

    /**
      * 预览token 
      */ 
    public static previewToken(config?: AxiosRequestConfig ) {
      return fetch.get<M.StringResponse>(`/grade/preview-token`,config);
    }

    /**
      * 发送月度报告到邮箱 
      */ 
    public static sendMonthlyGradeToEmail(studentId: number, gradePeriodId: number, email: string, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/grade/sendReportToEmail/${studentId}/${gradePeriodId}/${email}`, {}, { params:{},...config});
    }

    /**
      * 发送报告到邮箱 
      */ 
    public static sendToEmail(studentId: number, gradePeriodId: number, email: string, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/grade/${studentId}/${gradePeriodId}/${email}`, {}, { params:{},...config});
    }


}
export class MessageController {

    /**
      * 删除发件消息 
      */ 
    public static deleteFrom(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/message/deleteFrom`, { params:{messageMasterId},...config});
    }

    /**
      * 删除收件消息 
      */ 
    public static deleteTo(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.delete<void>(`/message/deleteTo`, { params:{messageId},...config});
    }

    /**
      * 获取发送消息详情 
      */ 
    public static getFromDetail(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageDetailResponse[]>(`/message/fromDetail`, { params:{messageMasterId},...config});
    }

    /**
      * 获取发件箱列表 
      */ 
    public static getFromList(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.FromListResponse>>(`/message/fromList`, { params:request,...config});
    }

    /**
      * 查询主页消息 
      */ 
    public static getHomePageMessage(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.HomePageMessageResponse>(`/message/homePageMessage`, { params:{studentId},...config});
    }

    /**
      * 查询读取情况 
      */ 
    public static getReadInfo(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageReadResponse>(`/message/readInfo`, { params:{messageMasterId},...config});
    }

    /**
      * 获取接收消息详情 
      */ 
    public static getToDetail(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.MessageDetailResponse[]>(`/message/toDetail`, { params:{messageId},...config});
    }

    /**
      * 获取收件箱列表 
      */ 
    public static getToList(request: M.MessagePageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ToListResponse>>(`/message/toList`, { params:request,...config});
    }

    /**
      * 查询是否有普通未读消息, 返回格式:{key:学生id, value:true(有未读)/false(无未读)} 
      */ 
    public static hasUnreadMessage(config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/message/hasUnread`,config);
    }

    /**
      * 查询未读消息数量 
      */ 
    public static hasUnreadSystem(config?: AxiosRequestConfig ) {
      return fetch.get<number>(`/message/hasUnreadSystem`,config);
    }

    /**
      * 查询是否有系统未读消息, 返回格式:{key:学生id, value:数量} 
      */ 
    public static hasUnreadSystemMessage(config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/message/hasUnreadSystemMessage`,config);
    }

    /**
      * 发送 
      */ 
    public static sendMessage(request: M.MessageSaveRequest, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/message/send`,request, config);
    }

    /**
      * 设置消息已读 
      */ 
    public static setRead(messageId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/message/setRead`, {}, { params:{messageId, },...config});
    }

    /**
      * 查询最新系统通知 
      */ 
    public static sysMessageNotice(config?: AxiosRequestConfig ) {
      return fetch.get<M.SysMessageNoticeResponse>(`/message/sys-message-notice`,config);
    }

    /**
      * 查询指定时间段最新未读消息 
      */ 
    public static unReadMessageCount(minute: number, config?: AxiosRequestConfig ) {
      return fetch.get<any>(`/message/unread-message-count`, { params:{minute},...config});
    }

    /**
      * 撤回 
      */ 
    public static withdraw(messageMasterId: number, config?: AxiosRequestConfig ) {
      return fetch.put<boolean>(`/message/withdraw`, {}, { params:{messageMasterId, },...config});
    }


}
export class CCACurriculumController {

    /**
      * 报名 
      */ 
    public static apply(request: M.ApplyBatchRequest, config?: AxiosRequestConfig ) {
      return fetch.put<M.ApplyBatchResultResponse[]>(`/cca/curriculum/apply`,request, config);
    }

    /**
      * 取消报名 
      */ 
    public static cancelApply(courseId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/cca/curriculum/cancelApply`, {}, { params:{courseId, studentId, },...config});
    }

    /**
      * 查询课程详情 
      */ 
    public static detail(courseId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.CCACurriculumResponse>(`/cca/curriculum/detail`, { params:{courseId},...config});
    }

    /**
      * 列表查询 
      */ 
    public static list(request: M.CCACurriculumPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.CCACurriculumWeeklyListResponse[]>(`/cca/curriculum/list`, { params:request,...config});
    }


}
export class ExamController {

    /**
      * 获取考试下拉框 
      */ 
    public static getExamDropdown(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/exam/dropdown`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 获取成绩单详情 
      */ 
    public static getTranscript(examId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentTranscriptResponse>(`/exam/transcript`, { params:{examId,studentId},...config});
    }


}
export class MomentsController {

    /**
      * 家长端点赞 
      */ 
    public static favor(favor: boolean, momentId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.put<void>(`/moments/favor`, {}, { params:{favor, momentId, studentId, },...config});
    }

    /**
      * 家长端查询班级圈 
      */ 
    public static getMoments(pageCurrent: number, pageSize: number, studentId: number, start?: number, end?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.MomentResponse>>(`/moments`, { params:{pageCurrent,pageSize,studentId,start,end},...config});
    }


}
export class TaskGradeController {

    /**
      * 获取单个学生成绩册 
      */ 
    public static getGradeBookDetail(courseId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeBookItemResponse>(`/task-grade/grade-book-detail`, { params:{courseId,studentId},...config});
    }

    /**
      * 获取学生有评分报告发送的学期 
      */ 
    public static getGradingSemesters(studentId: number, schoolYearId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.SemesterResponse[]>(`/task-grade/semester`, { params:{studentId,schoolYearId},...config});
    }

    /**
      * 获取报告详情 
      */ 
    public static getReportDetail(reportId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ReportDetailResponse>(`/task-grade/report/detail`, { params:{reportId},...config});
    }

    /**
      * 获取学生成绩册 
      */ 
    public static getStudentGradeBook(schoolYearId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.GradeBookResponse>(`/task-grade/grade-book`, { params:{schoolYearId,studentId},...config});
    }

    /**
      * 学生详情成绩 
      */ 
    public static getStudentGradeSummary(semesterId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentGradeSummaryResponse>(`/task-grade/student`, { params:{semesterId,studentId},...config});
    }


}
export class ClassMaterialController {

    /**
      * 获取课程资料详情 
      */ 
    public static detail(classMaterialId: number, studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.ClassMaterialResponse>(`/class/material/detail`, { params:{classMaterialId,studentId},...config});
    }

    /**
      * 获取课程资料列表 
      */ 
    public static getDetailList(request: M.ClassMaterialDetailPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ClassMaterialDetailPageResponse>>(`/class/material/detailList`, { params:request,...config});
    }

    /**
      * 获取课程资料列表 
      */ 
    public static getList(request: M.ClassMaterialPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.ClassMaterialPageResponse>>(`/class/material/list`, { params:request,...config});
    }


}
export class CurriculumController {

    /**
      * 获取周课表(学生) 
      */ 
    public static getClassArrangesByStudentAndWeek(studentId: number, start: number, end?: number, schoolYearId?: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.StudentWeeklyCurriculumResponse>(`/curriculum/student/`, { params:{studentId,start,end,schoolYearId},...config});
    }


}
export class SemesterController {

    /**
      * 获取当前学年 
      */ 
    public static getCurrentSchoolYear(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearResponse>(`/semester/currentSchoolYear`,config);
    }


}
export class DropDownController {

    /**
      * 清除所有未读教学课程 
      */ 
    public static clearUnRead(studentId: number, courseId: number, config?: AxiosRequestConfig ) {
      return fetch.post<void>(`/dropDown/clear-un-read`, {}, { params:{studentId, courseId, },...config});
    }

    /**
      * 获取国家列表 
      */ 
    public static getCountryList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/countryList`,config);
    }

    /**
      * 获取班主任和tutor 
      */ 
    public static getHeadTeacherAndTutors(studentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/teachers`, { params:{studentId},...config});
    }

    /**
      * 获学历列表 
      */ 
    public static getQualificationList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/qualificationList`,config);
    }

    /**
      * 获取学生关联课程 
      */ 
    public static getRelatedAllCourses(request: M.RelatedCourseRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.TaskCourseResponse[]>(`/dropDown/relatedAllCourses`, { params:request,...config});
    }

    /**
      * 获取学生关联课程 
      */ 
    public static getRelatedCourses(studentId: number, type: string, schoolYearId: number, deleteFlag: boolean, courseFlag?: boolean, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/relatedCourses`, { params:{studentId,type,schoolYearId,deleteFlag,courseFlag},...config});
    }

    /**
      * 获取全部学年列表 
      */ 
    public static getSchoolYearList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/schoolYearList`,config);
    }

    /**
      * 获取全部学年列表按 当前-未来-历史 
      */ 
    public static getSchoolYearRuleList(config?: AxiosRequestConfig ) {
      return fetch.get<M.SchoolYearDropDownResponse[]>(`/dropDown/schoolYearRuleList`,config);
    }

    /**
      * 获取年级列表 
      */ 
    public static getSectionList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/sectionList`,config);
    }

    /**
      * 根据年级id列表 获取科目列表（并集） 
      */ 
    public static getSubjectBySectionsUnion(request: M.SectionIdsRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/subjectsBySectionUnion`, { params:request,...config});
    }

    /**
      * 获取当前和未来学年列表 
      */ 
    public static getUnPassedSchoolYearList(config?: AxiosRequestConfig ) {
      return fetch.get<M.DropDownResponse[]>(`/dropDown/unPassedSchoolYearList`,config);
    }

    /**
      * 查询是否含未读教学课程 
      */ 
    public static unReadTeach(config?: AxiosRequestConfig ) {
      return fetch.get<M.UnReadResponse[]>(`/dropDown/un-read-teach`,config);
    }


}
export class HomeworkController {

    /**
      * 获取作业详情 
      */ 
    public static detail(homeworkStudentId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.HomeworkResponse>(`/homework/detail`, { params:{homeworkStudentId},...config});
    }

    /**
      * 获取作业列表 
      */ 
    public static getList(request: M.HomeworkPageRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.HomeworkPageResponse>>(`/homework/list`, { params:request,...config});
    }


}
export class AddressBookController {

    /**
      * 分页根据部门查询通讯录列表 
      */ 
    public static addressBookList(request: M.AddressBookRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PagedList<M.DepPersonResponse>>(`/address-book/dep-person`, { params:request,...config});
    }

    /**
      * 根据部门id查询部门信息 
      */ 
    public static departmentByDepId(depId: number, config?: AxiosRequestConfig ) {
      return fetch.get<M.DepDetailResponse>(`/address-book/department/byDepId`, { params:{depId},...config});
    }

    /**
      * 查询部门列表 
      */ 
    public static departmentList(request: M.DepQueryRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.DepartmentResponse[]>(`/address-book/department`, { params:request,...config});
    }

    /**
      * 个人通讯录 
      */ 
    public static personalBook(request: M.PersonalBookRequest, config?: AxiosRequestConfig ) {
      return fetch.get<M.PersonalBookResponse[]>(`/address-book/personal`, { params:request,...config});
    }


}
