















import { Component, Prop, Vue } from 'vue-property-decorator'
import { removeToken, removeSchoolInfo } from '@/utils/utils'

@Component
export default class ExceptionPage extends Vue {
  @Prop({
    default: '404',
  })
  public type!: string

  private get message(): any {
    return this.$route.query.message
  }

  private get title(): any {
    return this.$route.query.title
  }

  private get isPc(): any {
    return this.$store.state.isPc
  }

  public get config() {
    return {
      403: {
        img: 'https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg',
        title: '403',
        desc: this.$t('errorCode.403'),
      },
      404: {
        img: 'https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg',
        title: '404',
        desc: this.message ? this.message : this.$t('errorCode.404'),
      },
      phoneWarning: {
        img: 'https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg',
        title: this.title ? this.title : this.$t('tips.notAvailable'),
        desc: this.message ? this.message : this.$t('errorCode.phoneWarning'),
      },
    }
  }

  public handleToHome() {
    this.$router.push({ name: 'main' }).catch(err => console.log(err))
  }

  private logout(): void {
    removeToken()
    removeSchoolInfo()
    this.$store.commit('changeForceBack', true)
    this.$store.dispatch('refreshRoutes')
    this.$router.push({ name: 'login' }).catch(err => {})
  }
}
