import Axios from 'axios'
import { i18n } from '@/i18n/i18n'
import store from '@/store/index'
import { Dialog, Locale, Notify } from 'vant'
import enUS from 'vant/es/locale/lang/en-US'
import zhCN from 'vant/es/locale/lang/zh-CN'

export function getToken() {
  const tokenString = localStorage.getItem('token')
  if (tokenString) {
    const { token, expireTime } = JSON.parse(tokenString)
    const now = new Date().getTime()
    if (expireTime >= now) {
      return token
    } else {
      removeToken()
      return null
    }
  } else {
    const tempToken = sessionStorage.getItem('token')
    if (tempToken) {
      return tempToken
    } else {
      removeToken()
      return null
    }
  }
}

export function validateVersion(immediate = false, cb?) {
  let date = new Date().valueOf()
  const last = sessionStorage.getItem('lastValid')
  if (!last || date - Number(last) > 5 * 60 * 1000 || immediate) {
    sessionStorage.setItem('lastValid', date.valueOf().toString())
    fetch(`/version.json?t=${date}`, { method: 'GET' })
      .then(res => {
        if (!res.ok) return
        res
          .json()
          .then(res => {
            const currentVersion = localStorage.getItem('version')
            if (currentVersion !== res.version) {
              localStorage.setItem('version', res.version)
              localStorage.setItem('updateVis', 'false')
              window.location.reload()
            } else {
              cb?.()
            }
          })
          .catch(err => cb?.())
      })
      .catch(err => cb?.())
  } else {
    cb?.()
  }
}

export function setToken(token: string, remember: boolean) {
  if (remember) {
    removeToken()
    const day = 30
    const expireTime = new Date().getTime() + day * 24 * 3600 * 1000
    localStorage.setItem('token', JSON.stringify({ token, expireTime }))
  } else {
    removeToken()
    sessionStorage.setItem('token', token)
  }
}

export function removeToken() {
  localStorage.removeItem('token')
  sessionStorage.removeItem('token')
}

export function getSchoolInfo() {
  const schoolInfo = localStorage.getItem('schoolInfo')
  if (schoolInfo) {
    return JSON.parse(schoolInfo)
  } else {
    return null
  }
}

export function setSchoolInfo(schoolInfo) {
  removeSchoolInfo()
  localStorage.setItem('schoolInfo', JSON.stringify(schoolInfo))

  setLanguage()
}

export function setLanguage() {
  let locale = getLocale()
  let schoolInfo = getSchoolInfo()
  let languageList = {
    default: ['zh', 'en', 'enGB'],
    2: ['zh', 'en'],
    8: ['zh', 'enGB'],
  }
  let curLanguageList = Object.keys(languageList).includes(schoolInfo?.schoolId.toString())
    ? languageList[schoolInfo.schoolId]
    : languageList.default
  if (!locale || !curLanguageList.includes(locale)) {
    setLocale(curLanguageList[0])
    i18n.locale = curLanguageList[0]
    Locale.use('zh-CN', zhCN)
    store.commit('changeLocale', curLanguageList[0])
  } else {
    i18n.locale = locale
    locale === 'zh' ? Locale.use('zh-CN', zhCN) : Locale.use('en-US', enUS)
    store.commit('changeLocale', locale)
  }
}

export function removeSchoolInfo() {
  localStorage.removeItem('schoolInfo')
}

export function getUserAuth() {
  const userAuth = localStorage.getItem('userAuth')
  if (userAuth) {
    return JSON.parse(userAuth)
  } else {
    return null
  }
}

export function setUserAuth(userAuth) {
  removeUserAuth()
  localStorage.setItem('userAuth', JSON.stringify(userAuth))
}

export function removeUserAuth() {
  localStorage.removeItem('userAuth')
}

export function getLocale() {
  return localStorage.getItem('locale')
}

export function setLocale(locale: string) {
  return localStorage.setItem('locale', locale)
}

export function setCurrentStudent(id) {
  localStorage.setItem('studentId', id)
}

export function getCurrentStudent() {
  return localStorage.getItem('studentId')
}

export function getGuideViewStatus() {
  let status = localStorage.getItem('guideViewStatus')
  return status ? JSON.parse(status) : {}
}

export function setGuideViewStatus(status) {
  return localStorage.setItem('guideViewStatus', JSON.stringify(status))
}

export function getFile(url) {
  return new Promise((resolve, reject) => {
    Axios({ method: 'get', url, responseType: 'arraybuffer' })
      .then(res => {
        resolve(res.data)
      })
      .catch(err => {
        reject(err.toString())
      })
  })
}

export function getSchoolList() {
  const schoolList = sessionStorage.getItem('schoolList')
  if (schoolList) {
    return JSON.parse(schoolList)
  } else {
    return null
  }
}

export function setSchoolList(schoolList) {
  removeSchoolList()
  sessionStorage.setItem('schoolList', JSON.stringify(schoolList))
}

export function removeSchoolList() {
  sessionStorage.removeItem('schoolList')
}

export function getFirstLogin() {
  return localStorage.getItem('firstLogin')
}

export function setFirstLogin(status) {
  localStorage.setItem('firstLogin', status)
}

export function removeFirstLogin() {
  localStorage.removeItem('firstLogin')
}

export function getIntroShow() {
  let status = localStorage.getItem('introShow')
  return status ? JSON.parse(status) : {}
}

export function setIntroShow(status) {
  return localStorage.setItem('introShow', JSON.stringify(status))
}

export function toPage(pagination, result, cb) {
  if (!result?.length && pagination.current !== 1) {
    cb(Math.ceil(pagination.total / pagination.pageSize) || 1)
  }
  return -1
}

export function excludeSpecial(s) {
  // 去掉转义字符
  return s.replace(/[/\\'"\b\f\n\r\t]/g, '')
}

export function clearEmptyArray(array) {
  return array.map(item => {
    if (item.subOptions && item.subOptions.length) {
      item.subOptions = clearEmptyArray(item.subOptions)
      return item
    } else {
      return {
        ...item,
        subOptions: undefined,
      }
    }
  })
}

export function isBase64(str: string) {
  if (!str || str.trim() === '') {
    return false
  }
  try {
    return btoa(atob(str)) === str
  } catch (err) {
    return false
  }
}

export function createMergeArray(newNode, newList, splitter = '-'): any {
  if (newNode.subOptions && newNode.subOptions.length) {
    newNode.subOptions.forEach(item => {
      createMergeArray(
        {
          ...item,
          enValue: `${newNode.enValue}${splitter}${item.enValue}`,
          value: `${newNode.value}${splitter}${item.value}`,
          newKey: `${newNode.newKey || newNode.key}${splitter}${item.key}`,
          lastValue: item.value,
        },
        newList,
        splitter
      )
    })
  } else {
    newList.push(newNode)
  }
}

export function guid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export function aDownload(url: string, fileName: string, directDownload) {
  const suffixArray = [
    '.png',
    '.jpeg',
    '.jpg',
    '.pdf',
    '.bmp',
    '.tif',
    '.gif',
    '.pcx',
    '.tga',
    '.exif',
    '.fpx',
    '.svg',
    '.psd',
    '.cdr',
    '.pcd',
    '.dxf',
    '.webp',
  ]
  const index = fileName.lastIndexOf('.')
  const suffix = fileName.slice(index).toLowerCase()
  const enf = encodeURI(fileName)
    .replace(/&/g, '%26')
    .replace(/\+/g, '%2B')
    .replace(/\s/g, '%20')
    .replace(/#/g, '%23')
    .replace(/=/g, '%23')
  if (!directDownload) {
    window.open(suffixArray.includes(suffix) ? url : `${url}?attname=${enf || `download${suffix}`}`)
    return
  }
  var alink = document.createElement('a')
  alink.href = `${url}${directDownload ? `?attname=${enf || `download${suffix}`}` : ''}`
  alink.download = enf
  alink.style.display = 'none'
  document.body.appendChild(alink)
  alink.click()
  alink.remove()
}

export function calcBg(status) {
  const color = {
    intime: '#73d13d',
    late: '#ffc53d',
    excusedLate: '#FF6D00',
    illness: '#85a5ff',
    absent: '#ff4d4f',
    personal: '#ff9c6e',
    examAbsent: '#F759AB',
    holiday: '#b37feb',
    noRecords: '#fff',
    exams: '#69C0FF',
    others: '#BFBFBF',
    online: '#46C29A',
    weekendHoliday: '#b37feb',
  }
  return color[status] || '#fff'
}

export function calcSymbol(status) {
  const attenSym = {
    intime: 'P',
    late: 'L',
    excusedLate: 'EL',
    absent: 'A',
    personal: 'EA',
    examAbsent: 'MA',
    illness: 'S',
    holiday: 'V',
    exams: 'E',
    others: 'O',
    noRecords: '- -',
    online: 'OL',
    NA: 'NA',
    A: 'A',
  }
  return attenSym[status] || '- -'
}
