export default {
  platformName: 'Parent Platform',
  changeLocale: '语言切换',
  staticCode: {
    '1201': 'Unopen',
    '1202': 'Processing',
    '1203': 'Finished',
    '1204': 'Semester1',
    '1205': 'Semester2',
    '1208': 'Not Open',
    '1209': 'Ongoing',
    '1210': 'Graduated',

    // 学生状态
    '1014': 'Active',
    '1015': 'Graduate',
    '1016': 'Transfered',
    '1017': 'Enrolling',
    '1018': 'Transfering',
  },
  relation: {
    father: 'Father',
    mother: 'Mother',
    patGrandPa: 'Paternal Grandfather',
    patGrandMa: 'Paternal Grandmother',
    matGrandPa: 'Maternal Grandfather',
    matGrandMa: 'Maternal Grandmother',
    other: 'Other',
  },
  router: {
    homeworkDetail: 'Task Detail',
    homework: 'Task',
    studentDetail: 'Student Detail',
    diary: 'Student Log',
    CDDiary: 'Pupil conduct record',
    addDiary: 'Add Student Log',
    editDiary: 'Edit Student Log',
    attendance: 'Attendance',
    studentInfo: 'Student Info',
    curriculum: 'Timetable',
    myCurriculum: 'My Timetable',
    myAdjustCurriculum: 'My Adjust Timetable',
    calendar: 'Calendar',
    message: 'Message',
    inbox: 'Inbox',
    outbox: 'Outbox',
    sendMessage: 'Send Message',
    sentDetail: 'Detail',
    replyMessage: 'Reply Message',
    receivedDetail: 'Detail',
    personal: 'Personal',
    systemMessage: 'System Message',
    parentsMeeting: "Parents' Meeting",
    parentsMeetingDetail: "Parents' Meeting Detail",
    takeLeave: 'Take Leave',
    addLeave: 'Apply',
    editLeave: 'Edit',
    moment: 'Moment',
    assignment: 'Assignment',
    assignmentDetail: 'Assignment Detail',
    materialDetail: 'Resource Detail',
    courseMaterial: 'Class Resource',
    gradeBook: 'Grade Book',
    gradeBookDetail: 'Mission performance',
    report: 'Report',
    teaching: 'Teaching',
    home: 'Home',
    app: 'App',
    user: 'Me',
    messageDetail: 'Message Detail',
    diaryDetail: 'Diary Detail',
    leaveDetail: 'Leave Detail',
    teacherInfo: 'Teacher Info',
    studentTable: 'TimeTable',
    CDStudentTable: 'TimeTable',
    address: 'Directory',
    addressBook: 'Directory',
    schoolAddressBook: 'Faculty Directory',
    privateAddressBook: 'Personal Directory',
    announcement: 'Announcement',
    announcementDetail: 'Announcement Detail',
    studentCurriculum: 'Student TimeTable',
    lifeBlock: 'Life Block',
    confirmEnrollment: 'Confirm Enrollment',
    enrolledCourses: 'Enrolled Courses',
    enrollmentResult: 'Enrollment Result',
  },
  login: {
    passwordLogin: 'Account Login',
    verCodeLogin: 'Mobile Login',
    mobileOrEmail: 'Mobile/Email',
    mobile: 'Mobile',
    password: 'Password',
    verCode: 'Ver-code',
    enterVerCode: 'Please enter ver-code',
    enterMobile: 'Please enter mobile/email',
    enterPassword: 'Please enter password',
    getVerCode: 'Get ver-code',
    loginAuto: 'Auto login within 7 days',
    forgetPassword: 'Forget Password',
    login: 'Login',
    resendVerCode: 'Resend after {time}s',
    resetPwdTip: 'The password is 8-20 characters and must contain numbers and letters',
    confirmPwd: 'Confirm password',
    inconsistentPwd: 'Inconsistent Password',
    invalidPwd: 'Invalid Password',
    setPassword: 'Set Login Password',
    enter: 'Enter',
    userLogin: 'User Login',
    firstLogin: 'First Login',
    enterMail: 'Please Enter Email',
    mailTips: 'VerCode has been sended to {tips}',
    tips:
      'It is recommended to use Google Chrome and the latest version of Edge. Compatible with Safari. It is not recommended to use computers with a resolution lower than 1366*768 and mobile phones to view. Not compatible with IE browser.',
    phoneTips:
      'At present，parents log into IntSchool by mobile phone , only support the message function. Other functions will be launched iteratively. Please look forward to it. For more functions, please log into by computer.',
  },
  common: {
    integral: 'Integral',
    typeError: 'Type Error',
    textLimit11: 'Limit to 11 characters',
    submit: 'Submit',
    unsaveConfirm: 'Are you sure leave before content has been saved?',
    clear: 'Clear',
    seq: 'Sequence',
    operations: 'Operations',
    total: 'Total: {total} records',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    reset: 'Reset',
    resetPwd: 'Reset Password',
    enter: 'Please enter',
    select: 'Please select',
    confirm: 'Confirm',
    cancel: 'Cancel',
    true: 'Yes',
    false: 'No',
    gender: { male: 'Male', female: 'Female' },
    saveSuccess: 'Save Successfully',
    deleteSuccess: 'Delete Successfully',
    resetSuccess: 'Reset Successfully',
    save: 'Save',
    all: 'All',
    submitSuccess: 'Submit Successfully',
    terminateSuccess: 'Terminate Successfully',
    adjustSuccess: 'Adjust Successfully',
    attendanceSuccess: 'Set Attendance Successfully',
    view: 'View',
    enterContent: 'Please enter content',
    sendVerCodeSuccess: 'Send Ver-code Successfully',
    setPasswordSuccess: 'Set Password Successfully',
    send: 'Send',
    sendSuccess: 'Send Successfully',
    status: 'status',
    am: 'am',
    pm: 'pm',
    recall: 'recall',
    recallSuccess: 'Recall Successfully',
    recallFailed: 'Recall Failed',
    pageTotal: 'Total {total} items',
    finish: 'Finish',
    date: 'Date',
    loadMore: 'Load More...',
    noMore: 'No More Data',
    email: 'Email',
    days: 'Days',
    subjectClass: 'Subject Class',
    name: 'Name',
    deadline: 'Deadline',
    type: 'Type',
    subject: 'Subject Category',
    description: 'Description',
    attachment: 'Attachment',
    term: 'Term',
    schoolYear: 'School Year',
    backToHome: 'Back To Home',
    logout: 'Logout',
    score: 'Score',
    grade: 'Grade',
    createTime: 'Create Time',
    studentName: 'Student Name',
    CCAClass: 'Life Block',
    creator: 'Creator',
    time: 'Time',
    loadEnd: 'All Loaded',
    teacher: 'Teacher',
    classroom: 'Classroom',
    class: 'Class',
    noClassroom: 'No Room',
    download: 'Download',
    fold: 'Fold',
    unfold: 'Unfold',
    sent: 'sent',
    noData: 'No Data',
    noAttendance: 'No attendance record today',
    noTask: 'All tasks are completed',
    deleteConfirm: 'Are you sure delete?',
    next: 'Next',
    last: 'Last',
    copySuccess: 'Copy Successfully',
    copy: 'copy',
    copyEmail: 'Copy Email',
    selected: 'Selected {total} records',
    export: 'Export',
    share: 'Share',
  },
  main: {
    quit: 'Quit',
    helpDoc: 'Help Document',
    feedback: 'Feedback',
    feedbackTips:
      'If you encounter technical problems during use, please send an email to the school mailbox:',
    introduction: 'IntSchool Introduction',
    homeIntro:
      "Quickly view new messages\nReal-time synchronization of students' attendance status on the day\nStudents' recent course progress can be seen at a glance",
    mesIntro:
      'Home-school communication is smoother, mail exchanges, and information bombing is rejected',
    appIntro: `Teaching courses, transcripts, and reports help you understand the student's academic status\n
      Diary and class circle help you to record all-round growth records of students and students' rewards and punishments, life, health, etc. and school dynamics every day`,
    guide: 'Newbie Guide',
    moreDetails: 'More Details',
    attendance: "You can check live updates Pupils' daily attendance",
    CDStudentTable: 'Get a quick overview of student scheduling',
    studentDetail:
      "You can copy the teacher's email directly from the class and course information",
    report: "Helping you keep track of pupil's academic progress",
  },
  intros: {
    studentDetail:
      'Detailed electronic files (course schedules, term reports, teacher information)',
    teaching: 'Easy to understand the assignment, check the course resource at any time',
    gradeBook: 'Academic progress at a glance',
    diary: `Students' all-round growth record, such as rewards and punishment, life and health.`,
    moment: 'Class, campus dynamics can be seen',
    attendance: `Synchronize students' class attendance status in real time`,
    message: 'Better communication between home and school, email exchange',
    parentsMeeting: 'Online rapid appointment of parents meeting, time clear, clear itinerary',
    takeLeave: 'No cumbersome steps, leave process is fast and convenient',
    calendar: 'One-click access to school calendar information, schedule more clear',
  },
  student: {
    points: 'Score',
    studentInfo: 'Student',
    parentInfo: 'Parent',
    curriculumInfo: 'Timetable',
    teacherInfo: 'Teacher',
    subjectInfo: 'Course',
    classInfo: 'Class',
    classType: 'Class Type',
    typeList: {
      1251: 'Homeroom',
      // 1252: 'Advisory',
      1253: 'Advisory',
      1255: 'Subject Class',
      1257: 'Dormitory',
      1256: 'Night Study',
      1258: 'Life Block',
    },
    attendanceInfo: 'Attendance',
    instruction: 'Instruction',
    chineseName: 'Name (Chinese)',
    englishName: 'Name (English)',
    firstName: 'First Name (pinyin)',
    lastName: 'Last Name (pinyin)',
    gender: 'Gender',
    birthday: 'Birthday',
    idType: 'ID Type',
    idNumber: 'ID Number',
    nationality: 'Nationality(Subject to passport)',
    email: 'Email',
    residentialAddress: 'Residential Address',
    address: 'Address',
    section: 'Grade',
    class: 'Class',
    studentId: 'Student ID',
    house: 'House',
    startYear: 'Start Year',
    departureTime: 'Departure Time',
    schoolBus: 'School Bus',
    route: 'Route',
    placeToMeet: 'Place to Meet',
    accommodation: 'Accommodation',
    platformLoginRight: 'Platform Login',
    loginAccount: 'Login Account',
    password: 'Password',
    resetPassword: 'Reset Password',
    siblingsInfo: 'Sibling',
    childName: 'Child Name',
    firstParent: 'Main Contact',
    secondParent: 'Second Contact',
    parentChineseName: 'Parent/Guardian Chinese Name',
    parentEnglishName: 'Parent/Guardian English Name',
    parent: 'Parent',
    mobile: 'Mobile',
    relation: 'Relation',
    employer: 'Company',
    position: 'Title',
    subject: 'Subject Category',
    courseClass: 'Subject Class',
    teacher: 'Teacher',
    teacherType: 'Teacher Type',
    teacherName: 'Teacher Name',
    medical: 'Medic',
    diet: 'Food',
    national: 'National',
    scholarship: 'Scholarship',
    totalCourse: '{total} classes this week',
    point: 'Point',
    switchChild: 'Switch Child',
    identification: 'Identification',
    passport: 'Passport',
    other: 'Other',
    idTypeList: {
      '1260': '@:(student.identification)',
      '1261': '@:(student.passport)',
      '1262': '@:(student.other)',
    },
    downloadReport: 'Download',
    grading: 'Grading',
    honor: { honorTime: 'Honor Time', honorDetail: 'Honor Detail' },
    leadership: { tenure: 'Tenure', leaderDetail: 'Leadership Detail' },
    label: { honor: 'Honors', leadership: 'Leadership' },
    cropTip:
      'Mouse wheel zoom control image display size, drag and drop to adjust the image position',
    preview: 'Preview',
    reupload: 'Reupload',
    uploadAvatar: 'Upload Avatar',
    wrongAvatar: 'Wrong Picture type',
    wrongBrowser: 'Browser Error',
    admissionTime: 'Admission Time',
    report: 'Report',
    sendReport: 'Send report to email',
    finalScore: 'Final Percentage',
    reportName: 'Report Name',
    gradePeriod: 'Grade Period',
    sent: {
      true: 'Sent',
      false: 'Not send',
    },
    gradeBook: 'Grade Book',
    reportDownloadTip:
      'Please log into PC to download PDF report, Android user need to download QQ or other browser to preview',
    eveningStudyRoom: 'Night Study',
    dormitory: 'Dormitory',
    scoreDetail: 'Score Detail',
    viewTaskResults: 'View task results',
    reportType: 'Report Type',
    reportTypeList: {
      midTerm: 'Mid-term Report',
      endTerm: 'End-term Report',
      fullSchoolYear: 'Yearly Report',
      monthly: 'Monthly Report',
      longTerm: 'Long Term Report',
      exam: 'Exam Report',
    },
    curriculum: 'Curriculum',
    accountInfo: 'Account',
    outClass: 'Out Of Class',
    graduationProgress: 'Graduation Progress',
  },
  homework: {
    assignment: 'Details',
    saveDraft: 'Save Draft',
    myHomework: 'My Task',
    type: 'Type',
    lineWork: 'Line Work',
    offLineWork: 'OffLine Work',
    suffixHomeWorkName: 'Task',
    publishScoreConfirm:
      'After successful release, the score cannot be modified. confirm the release?',
    publishScoreSuccess: 'Publish Score Success',
    batchMark: 'Batch Mark',
    finishMark: 'Finish Mark',
    insertFailed: 'Insert Failed',
    uploadImgs: 'Upload Imgs',
    scoreStatus: 'Score Status',
    view: 'View',
    mark: 'Mark',
    scorePublished: { true: 'Published', false: 'Unpublished' },
    studentName: 'Name',
    handInStatus: 'HandIn Status',
    handInTime: 'Hand In Time',
    score: 'Score',
    comments: 'Comments',
    tipOne: 'The deadline is {days} day away',
    tipTwo: '{days} day past the deadline',
    publishPoints: 'Publish Points',
    accessory: 'Attachments',
    remindStudent: 'Remind Student',
    remindSuccess: 'Remind Success',
    complete: 'Complete',
    unComplete: 'unComplete',
    gradeTypeList: {
      '1031': 'No Grade',
      '1032': 'Centesimal',
      '1033': 'Grading',
    },
    selectHandInOnline: 'Please Select HandInOnline',
    selectGradeType: 'Please Select GradeType',
    selectCourse: 'Please Select Course',
    enterHomeworkName: 'Enter Task Name',
    enterHomeworkContent: 'Enter Task Content',
    gradeType: 'Grade Type',
    handInOnline: 'Hand In Online',
    publishHomework: 'Publish Task',
    deadlineTime: 'Deadline',
    statusList: {
      '1011': 'Unsubmit',
      '1012': 'Submited',
      '1013': 'Submited beyond time',
      '1021': 'On going',
      '1022': 'Finished',
    },
    homeContent: 'Home Content',
    name: 'Name',
    sectionName: 'Grade',
    subjectName: 'Subject Category',
    courseName: 'Subject Class',
    completeNum: 'Completeness',
    creatorName: 'Creator',
    publishTime: 'Publish Time',
    CCAClass: 'Life Block',
    courseClass: 'Subject Class',
    CCAClassHomework: 'Life Block Task',
    courseClassHomework: 'Subject Class Task',
    completeStatus: 'Status',
  },
  diary: {
    describe: 'Description',
    diaryDetail: 'Student Log Detail',
    totalPoints: 'Points',
    addIntroduction: 'Add Introduction',
    injuredPart: 'Injured partion',
    uploadtext: 'Upload file',
    inputText: 'Please input confirm content',
    receipt: 'Recipient',
    sendEmail: 'Send Email',
    shareParent: 'Share Parent Platform',
    shareStudent: 'Share Student Platform',
    accessory: 'Attachments',
    addStudent: 'Add Student',
    relativeStudent: 'Linked Student',
    introduction: 'Introduction',
    place: 'Place',
    time: 'Time',
    achievement: 'achievement',
    attendance: 'attendance',
    className: 'Homeroom',
    consultation: 'consultation',
    countNum: 'Num',
    detention: 'detention',
    dormitory: 'dormitory',
    incident: 'incident',
    medical: 'medical',
    points: 'points',
    studentNName: 'studentName',
    diaryType: 'Student Log Type',
    childType: 'Sub Type',
    score: 'Points',
    section: 'Grade',
    class: 'Class',
    house: 'House',
    studentName: 'Name',
    createTime: 'Create Time',
    reset: 'Reset',
    fold: 'Fold',
    unFold: 'unFold',
  },
  exam: {
    downloadTranscript: 'Export',
    writeComment: 'Write Comment',
    house: 'House',
    teachers: 'Teacher',
    courseClass: 'Subject Class',
    subject: 'Subject Category',
    teacherComment: 'Teacher Comment',
    scholasticAttainment: 'Scholastic Performance',
    addComment: 'Add Comment',
    name: 'Name',
    code: 'Student ID',
    className: 'Homeroom',
    gradings: 'Exam Points',
    comment: 'Comment',
    tipt: 'The exam is finished,only see it',
    tip:
      'The exam is locked and cannot be edited. to edit, please contact the administrator/the exam is over',
    year: 'School Year',
    exam: 'Exam',
    section: 'Grade',
    topScore: 'Highest score',
  },
  attendance: {
    date: 'Date',
    statusList: {
      intime: 'Present',
      late: 'Late',
      excusedLate: 'Excused Late',
      absent: 'Absent',
      examAbsent: 'Missed Assessment',
      personal: 'Excused Absence',
      illness: 'Sick',
      holiday: 'Vacation',
      exams: 'Exams',
      others: 'Others',
      weekendHoliday: 'Vacation',
      noRecords: 'No Records',
      online: 'Online',
    },
    detailTip1:
      '"P" means punctual, "OL" means online, "L" means late, "EL" means excused late, "A" means absent, "EA" means absent(excused leave),"MA" means missed assessment,"S" means sick, "V" means vacation, "E" means exams, "O" means others',
    detailTip2: 'Move mouse on the icon to view remark',
    detailTip3:
      '"P" means punctual,"L" means late, "A" means absent, "EA" means absent(excused leave)',
    dailyPeriod: 'Period',
    classPeriod: 'Class',
    day: 'Day',
  },
  message: {
    title: 'Title',
    recipient: 'Recipient',
    sender: 'Sender',
    recipientError: 'Please Select Recipient',
    sendTime: 'Send Time',
    sendMessage: 'Send Message',
    content: 'Content',
    readStatus: 'Read Status',
    attachments: 'Attachments',
    emailNotice: 'Email notice',
    uploadAttachments: 'Upload Attachments',
    attachmentTip1: 'A single attachment is limited to 100m,',
    attachmentTip2: 'add up to 10 attachments',
    enterTitle: 'Please enter title',
    titleLimit: 'The title must not exceed 100 characters',
    enterContent: 'Please enter content',
    addTeachers: 'Add Teachers',
    addParents: 'Add Parents',
    addStudents: 'Add Students',
    invalidFileSize: 'A single attachment cannot exceed 100m',
    invalidFileFormat:
      'Only accept files with suffix of doc, docx, xls, xlsx, ppt, pptx, pdf, jpg, jpeg and png',
    receivedTime: 'Received Time',
    reply: 'Reply',
    forward: 'Forward',
    recall: 'Recall',
    recalled: 'Recalled',
    history: 'History Message',
    addRecipient: 'Add Recipient',
    sentTo: 'Sent To {names}',
    normalMes: 'Normal Message',
    recallSuccess: 'Recall Successfully',
    recallFailed: 'Recall failed, the recipient has read the message.',
    newMessageNotice: 'New Message Notice',
    receivedMessage: `You have received new messages:`,
    systemMessage: '1 system message | {num} system messages',
    normalMessage: '1 normal message | {num} normal messages',
  },
  addressBook: {
    name: 'Name',
    displayName: 'Display Name',
    type: 'Type',
    gender: 'Gender',
    position: 'Title',
    email: 'Email',
    switchDisplay: 'Display To Parents and Students',
    editDepartment: 'Edit Department',
    editExternalStaff: 'Edit External Staff',
    addExternalStaff: 'Add External Staff',
    addDepartment: 'Add Department',
    editDepartmetn: 'Edit Departmetn',
    deleteDepartment: 'Delete Department',
    confirmDeleteDepartment: 'Are You Sure TO Delete Department',
    confirmDeleteStaff: 'Are You Sure TO Delete Staff',
    contactNumber: 'Contact Number',
    contactEmail: 'Contact Email',
    introduction: 'Introduction',
    enterName: 'Please input name',
    manageDepartmentMember: 'Manage Department Member',
    externalStaff: 'External Staff',
    manageExternalStaff: 'Manage External Staff',
    staff: 'School Staff',
    addTeacher: 'Add Teacher',
    deleteConfirm: 'Are you sure to delete this staff?',
    trrigerError: 'Fail to display,please try again later',
    enterDepName: 'Please input department name',
    enterlegalEmail: 'Please input legal email',
    enterEmail: 'Please input email',
    teachInfo: 'Teach',
    teacherType: 'Teacher Type',
    categoryDetails: 'Category Details',
  },
  calendar: {
    addSchedule: 'Add Schedule',
    setDate: 'Set working day / vacation',
    parentView: 'View Parent View',
    closeParentView: 'Exit Parent View',
    remark: 'Remark',
    location: 'Location',
    time: 'Time',
    date: 'Date',
    workingDay: 'Working Day',
    holiday: 'Vacation',
    summerHoliday: 'Summer Vacation',
    winterHoliday: 'Winter Vacation',
    visibleToParent: 'Visible to parents and students',
    invisibleToParent: 'Invisible to parents and students',
    holidayPlaceholder: 'Enter vacation name',
    deleteConfirm: 'Are you sure to delete this schedule?',
    semesterStartDate: 'Start of semester',
    semesterEndDate: 'End of semester',
    allDay: 'All Day',
    status: 'Status',
    viewSchedule: 'View Schedule',
    name: 'Name',
  },
  personal: {
    disableConfirm:
      'If you disabled account, the parent will not be able to login, are you sure to disable?',
    enableConfirm:
      'If you enabled account, the parent will be able to login, are you sure to enabled?',
    disable: 'Disable',
    enable: 'Enable',
    enableSuccess: 'Enable successfully',
    disableSuccess: 'Disable successfully',

    passwordUnLaw: 'The password is 8-20 characters and must contain numbers and letters',
    accountError: 'Do not add yourself ',
    duplicateMobile: 'Duplicate Mobile!',
    duplicatedMobile: 'Mobile already exists',
    duplicatedEmail: 'Email already exists',

    duplicateEmail: 'Duplicate Email',
    forbidden: 'Forbidden',
    oldMobileNotRight: 'Please input right old mobile',
    relation: 'Relation',
    mobile: 'Phone Number',
    addParentAccount: 'Add parent account',
    editParentAccount: 'Edit parent account',
    duplicatedRelations: 'relation already existed',
    selectRelation: 'Please select relationship',

    nameValidateTip: 'Fill in at least one chinese and english name',
    isExistedMobile: 'Mobile is existed',
    textLimit50: 'Limit to 50 characters',
    enterName: 'Please input chinese name',
    enterenName: 'Please input english name',
    modifyMobile: 'Modify mobile',
    studentName: 'Name',
    parentName: 'Parent Name',
    parentAccount: 'Parent Account',
    title: 'Personal Information',
    edit: 'edit',
    name: 'chinese name',
    enName: 'english name',
    sex: 'Gender',
    position: 'Title',
    secret: 'Password',
    email: 'Email',
    modifySecret: 'Modify Password',
    modifyEmail: 'Modify Email',
    nowSecret: 'Now Password',
    newSecret: 'New Password',
    confirmSecret: 'Confirm Password',
    inputSecret: 'please input Password',
    inputNewSecret: 'please input new Password',
    inputConfirmSecret: 'please confirm Password',
    wrongPassword: 'Password are not same.',
    passwordLimit: 'Password wrong or type wrong',
  },
  transfer: {
    listTitle: '{title} List',
    selectedNum: 'Selected {num} {title}s',
    deleteAll: 'Delete All',
    selectAll: 'Select All',
    enterName: 'Please enter {title} name',
  },
  weekday: {
    '0': 'Sunday',
    '1': 'Monday',
    '2': 'Tuesday',
    '3': 'Wednesday',
    '4': 'Thursday',
    '5': 'Friday',
    '6': 'Saturday',
    7: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
  },
  notFound: {
    notFound: 'Sorry, the page you visited does not exist.',
    backToHome: 'Back to Home',
  },
  parentsMeeting: {
    reservable: 'Bookable',
    reserved: 'Booked',
    refreshTips: 'After reaching the booking start time, please refresh the page to start booking.',
    meetingName: "Parents' Meeting Name",
    reserveMeeting: 'Book Meeting',
    meetingTime: 'Meeting Time',
    reserveBeginTime: 'Booking Start',
    teacherReservable: 'Teacher Bookable',
    cancelReserve: 'Cancel Booking',
    reserve: 'Booking',
    cancelConfirm: 'Are you sure to cancel this meeting?',
    teacher: 'Teacher',
    reservedTime: 'Booking Time',
    classroom: 'Room',
    onlineLink: 'Online Meeting Link',
    status: 'Status',
    duration: 'Duration',
    confirmTitle: 'Are you sure to book this meeting?',
    failTips: 'You should book meetings which have failed reserving again after refreshing',
    success: 'Success',
    fail: 'Failed',
    got: 'Got it',
    updateSuccess: 'Update Success',
    cancelReservedSuccess: 'Cancel Booking Success',
    pending: 'Not Start',
    processing: 'Processing',
    finished: 'Finished',
    fullReserved: 'Full',
    unbeginTips: 'Cannot book before booking start',
    bookResult: 'Book Result',
    reserveTime: 'Book Time',
    started: 'Started',
    ended: 'Ended',
    DNS: 'Not Start',
    copyLink: 'Copy',
    linkTip: 'After copying, please use your browser to open',
  },
  takeLeave: {
    addLeave: 'Apply',
    leaveTime: 'Leave Time',
    leaveType: 'Leave Type',
    leaveDuration: 'Duration',
    approver: 'Approver',
    holiday: 'Vacation',
    illness: 'Sick',
    approved: 'Approved',
    declined: 'Rejected',
    pending: 'Approving',
    retrieved: 'Recalled',
    startTime: 'Start Time',
    endTime: 'End Time',
    selectStartTime: 'Please Select Start Time',
    selectEndTime: 'Please Select End Time',
    reason: 'Reason',
    student: 'Student',
    selectTimeType: 'Please select am or pm',
    selectApprover: 'Please Select Approver',
    recallConfirm: 'Are you sure to recall this leave apply?',
    startLaterThanEnd: 'Start time is later than end time',
    endEarlierThanStart: 'End time is earlier than start time',
    pleaseEnterReason: 'Please Enter Reason',
    calendarConflict: 'The selected date does not include the school day',
    leaveReason: 'Reason',
    leavePerson: 'Leave Person',
    leaveStatus: 'Status',
    leaveDetail: 'Leave Detail',
    rejectReason: 'Reason',
    personal: 'Excused Leave',
    exams: 'Exam',
    others: 'Others',
    apply: 'Apply',
    approveStatus: 'Status',
  },
  moment: {
    expandContent: 'Expand',
    collapseContent: 'Collapse',
    totalLike: 'Total {total} Person Like',
    translation: 'Translation',
  },
  systemMessage: {
    bookSuccess: "Parent's Meeting Book Successfully",
    content: {},
  },
  tips: {
    setEmailSuccess: 'Set Email Success',
    nonExistSchool: 'Non-Existent School',
    uploadFailed: 'Upload Failed',
    attachmentTip: 'Single attachment is limited to {size} and add up to {num} attachments',
    rejectFileTips: 'Files with the suffix: {tips} are unacceptable',
    notAvailable: 'Not Available',
    pageWarning: 'You has no {function} function, no data at present',
    noTeacher: 'Currently no available teacher.',
    selectTime: 'Please select time',
    selectType: 'Please select type',
    textLimit: 'Limit to {num} characters',
    recallConfirm: 'Are you sure to recall?',
    enterlegalEmail: 'Please input legal email',
  },
  errorCode: {
    '403': 'Sorry, you do not have access to this page',
    '404': 'Sorry, the page you visited does not exist.',
    '1010': 'Incorrect Account Or Password',
    1038: 'Invalid or Expired VerCode',
    '1039': 'Incorrect VerCode',
    '1041': 'Account Does Not Exist',
    '1044': 'Account disabled, please contact the teacher',
    phoneWarning:
      'Other functions will be iteratively brought online, please stay tuned. More features please log in the computer side to view.',
  },
  assignment: {
    totalIncluded: 'Total Included',
    assignmentType: 'Assignment Type',
    assignmentDetail: 'Assignment Detail Of {name}',
    online: 'Online',
    offLine: 'Offline',
    creator: 'Creator',
    publishTime: 'Publish Time',
    topScore: 'Top Score',
    handInAfterDead: 'Hand In After Deadline',
    allow: 'Allow',
    disallow: 'Disallow',
    Submit: 'Submitted',
    notSubmit: 'Not Submitted',
    myAssignment: 'My Assignment',
    attachment: 'Attachment',
    scoreAllow: 'Score Allow',
    remark: 'Remark',
    comment: 'Comment',
    incomplete: 'Incomplete',
    late: 'Late',
    missing: 'Missing',
  },
  courseMaterial: {
    name: 'Name',
    courseTime: 'Date',
    course: 'Subject Class',
    instruction: 'Instruction',
    attachment: 'Attachment',
    courseClassMaterial: 'Subject Class Resource',
    CCAClassMaterial: 'Life Block Resource',
    courseClass: 'Subject Class',
    CCAClass: 'Life Block',
    subject: 'Subject Category',
    creator: 'Creator',
    createTime: 'CreateTime',
    materialDetail: 'Resource Detail',
    SubMaterialDetail: 'Substitute Resource Detail',
    updater: 'Updater',
    updateTime: 'Update Time',
    fileNum: 'File Num',
  },
  dormitory: {
    room: 'Room',
    studyRoom: 'Night Study',
    selfStudy: 'Night Study',
  },
  teaching: {
    assignment: 'Assignment',
    resource: 'Resource',
    startDate: 'Start Date',
    taskTip: 'no task | 1 ongoing task are not submitted | {total} ongoing tasks are not submitted',
    allRead: 'All Read',
    allReadConfirm: 'Confirm to Read All?',
  },
  home: {
    newMessage: 'New Message',
    todayAttendance: "Today's Attendance",
    processingAssignment: 'Processing Assignment',
    checkAll: 'All',
    notice: 'Announcement',
  },
  placeholder: {
    selectSchoolYear: 'Select School Year',
    selectClass: 'Select Class',
    inputContent: 'Enter Content',
    inputTitle: 'Enter Title',
  },
  address: {
    campusAddress: 'Faculty Directory',
    personalAddress: 'Personal Directory',
    1251: 'Homeroom',
    1253: 'Advisory',
    1255: 'Subject Class',
    1257: 'Dormitory',
    1256: 'Night Study',
    1258: 'Life Block',
    email: 'email',
    mobile: 'mobile',
    copy: 'copy',
    exterPerson: 'External Person',
    copySuccess: 'Copy Succeeded!',
  },
  announcement: {
    all: 'All',
    campusActivity: 'Campus Activite',
    campusPolicy: 'Campus Policy',
    attention: 'Attention',
    search: 'Search',
    edit: 'The announcement was edited at {time}',
    department: 'Department',
    time: 'Time',
    status: 'Status',
    publisher: 'Publisher',
    isTop: 'Top Or Not',
    topEndTime: 'Top End Time',
    published: 'Published',
    unpublished: 'Unpublished',
    yes: 'Yes',
    no: 'No',
    keyword: 'Keyword',
    date: 'Date',
    tag: 'Tag',
  },
  parentAccount: {
    log: 'Operations Log',
    parentAccount: 'Parent Account',
    jump2PC: 'Login ON PC >>',
    jump2Mobile: 'Scan the QR code to login on mobile phone',
    associated: 'Associated Students',
    1001: 'Enabled',
    1002: 'Disabled',
    addAccount: 'Add Account',
    editAccount: 'Edit Account',
    viewAccount: 'Account Detail',
    enableAccount: 'Enable ACcount',
    disableAccount: 'Disable Account',
    resetPwd: 'Reset Password',
    deleteAccount: 'Delete Account',
    connectAccount: 'Connect Account',
    account: 'Account(phone number)',
    email: 'Email',
    soleParent: 'Sole parent. Cannot cancel the connection',
    cancel: 'Cancel',
    operator: 'Operator',
    operationsType: 'Operations Type',
    operations: 'Operations',
    time: 'Operation Time',
    type: {
      1701: 'Add Account',
      1702: 'Edit Account',
      1704: 'Eable Account',
      1705: 'Disable Account',
      1706: 'Reset Password',
      1703: 'Delete Account',
    },
    enterWorkUnit: 'Please input workunit',
    enterWorkPosition: 'Please input workunit',
    selectStudent: 'Please select student',
    enterAccount: 'Please enter account',
    deleteAccountTips: 'Please contact the administrator if you want to delete account',
    highestQlfctn: 'Highest Qualification',
    graduationInstitution: 'Graduation Institution',
    weChat: 'WeChat',
  },
  report: {
    shareTitle: 'Student Score Report',
  },
  lifeBlock: {
    enrollmentNote: 'Enrollment Notes',
    enrollmentNoteDetail:
      "Every day's extended courses are held at the same time, so you can only successfully register for one class each day, but you can tick three courses according to your preference, and the system will give you priority to grab the class in order. You can choose courses every day, and submit them after checking them uniformly.",
    understood: 'Understood',
    searchTip: 'Search for course name, teacher name',
    charge: 'Charge',
    notStart: 'Not Start',
    enrolling: 'Enrolling',
    enrolled: 'Enrolled',
    enrollTime: 'Enroll Time',
    teacher: 'Teacher',
    enroll: 'Enroll',
    selectedCourse: 'Selecteed Courses',
    pullText: 'Pull to refresh course list',
    looseText: 'Release to refresh course list',
    loadText: 'Loading...',
    successText: 'Refresh successfully',
    courseDescription: 'Class Description',
    schoolYear: 'School Year',
    classTime: 'Time',
    allowScope: 'Enroll Range',
    minEnrollNum: 'Minimal',
    maxEnrollNum: 'Maximum',
    classroom: 'Classroom',
    courseType: 'Course Type',
    isCharge: 'Fee',
    isCourseClass: 'Is Subject Class',
    enrolledCourses: 'Enrolled Courses',
    confirmEnrollmentTip:
      'Registration will be based on the following priority order. Long press and drag to adjust the sequence',
    cancelEnroll: 'Cancel',
    viewEnrolledCourses: 'View Enrolled Courses',
    complete: 'Complete',
    enrollSuccess: 'Enrolled successfully',
    enrollFailed: 'Enrolled failed',
    cancelTip: 'Confirm to cancel the enrollment?',
    cancelSuccess: 'Cancel successfully',
    staticCode: {
      1001: 'Unopen',
      1021: 'Enrollable',
      1022: 'Enrolled',
      1023: 'Rejected',
      1024: 'Enroll Success',
      1025: 'Enroll Failed',
      1026: 'Full',
      1011: 'Weekly',
      1012: 'Biweekly',
      1013: 'Once',
    },
    type: {
      1001: 'School Course',
      1002: 'Out-Of-School Vendors Course',
    },
  },
  leaveApproval: {
    student: 'Name',
    studentId: 'Student Id',
    class: 'Homeroom',
    college: 'Advisory',
    leaveTime: 'Leave Time',
    leaveType: 'Type',
    leaveDuration: 'Duration',
    approving: 'Approving',
    approved: 'Approved',
    approvedLabel: 'Approved',
    pending: 'Approving',
    retrieved: 'Recalled',
    auditRetrieved: 'Recalled',
    declined: 'Rejected',
    leavePerson: 'Applicant',
    leaveStatus: 'Status',
    leaveReason: 'Reason',
    holiday: 'Vacation',
    illness: 'Sick',
    passConfirmTips:
      'After the leave is passed, the attendance status will be automatically synchronized to confirm the approval of the leave, are you sure to pass?',
    rejectConfirm: 'Reject Confirm',
    passConfirm: 'Pass Confirm',
    days: 'Days',
    rejectReason: 'Reason',
    rejectConfirmTitle: 'Are you sure to reject this apply?',
    passConfirmTitle: 'Are you sure to pass this apply?',
    exams: 'Exam',
    others: 'Others',
    personal: 'Excused Leave',
    replyDemo: 'Reply Demo',
    cancel: 'cancel',
    cancelConfirm: 'Are you sure to cancel?',
    startLaterThanEnd: 'Start time is later than end time',
    endEarlierThanStart: 'End time is earlier than start time',
    apply: 'Apply',
    modifyTime: 'Modify Time',
    schoolBus: '@:(myClass.student.schoolBus)',
    accommodation: '@:(myClass.student.accommodation)',
    dormitory: '@:(myClass.student.dormitory)',
  },
  monthlyMgm: {
    name: 'Name',
    grade: 'Grade',
    house: 'Advisory',
  },
  graduation: {
    graduationRqm: 'Graduation Standard',
    progress: 'Graduation Progress',
    compulsoryCredit: 'Compulsory Credits',
    electiveCredit: 'Elective Credits',
    finishedCredit: 'Finished Credit',
    finished: 'Finished',
    inProgress: 'In Progress',
    courseAdmission: 'Course Criteria{index}',
    elective: 'Elective',
    gotCredits: 'Got Credits',
    course: 'Course',
    remark: 'Remark',
    credit: 'Credit',
    viewRequirements: 'View Graduation Standard',
    totalCredit: 'Total credits',
    compulsoryLabel: 'Compulsory (credits: {credit})',
    mark: 'Mark',
    courseGroup: 'Course Group{index}',
    electiveLabel: 'Elective (credits: {credit})',
    maxRecognizedCreitds: 'Maximum Recognized Creitds',
    EXTRA: 'Out-Of-School Course',
    OVERFLOW: 'Exceeding the required conditions, counted as being selected for repair',
  },
}
