




























































































































































































































































































































import { Component, Vue, Inject } from 'vue-property-decorator'
import {
  setToken,
  setLocale,
  setUserAuth,
  getToken,
  getSchoolInfo,
  removeToken,
  removeFirstLogin,
  excludeSpecial,
} from '@/utils/utils'
import { LoginController, DropDownController, ParentController } from '@/services/request.service'

@Component
export default class Login extends Vue {
  @Inject() public readonly baidu!: any

  private verCodeLoginForm: any
  private passwordLoginForm: any
  private resetPwdForm: any
  private verifyCodeTimer = 0
  private verifyLoading = false
  private loginLoading = false
  private resetPwdVisible = false
  private resetLoading = false
  private areaCodeList: Array<any> = []
  private verCodeLoading = false
  private resetVerCodeLoading = false
  private timer: any = null
  private verCodeTimer = 0
  private resetTimer: any = null
  private resetVerCodeTimer = 0
  private mobile = ''
  private resetMailTips = ''

  private schoolId: any = (getSchoolInfo() || {}).schoolId
  private language: string = ''
  private languages: any = {
    zh: '中文',
    en: 'English(American)',
    enGB: 'English(British)',
  }

  private get locale(): string {
    return this.$store.state.locale
  }

  private get school(): any {
    const school = getSchoolInfo()
    if (school && Object.keys(school).length) {
      return school
    } else {
      return {
        name: '',
        enName: '',
      }
    }
  }

  private get isPc(): any {
    return this.$store.state.isPc
  }

  private created(): void {
    this.getAreaCodeList()
  }

  private mounted() {
    this.language = this.languages[this.locale]
  }

  private beforeCreate(): void {
    this.verCodeLoginForm = this.$form.createForm(this)
    this.passwordLoginForm = this.$form.createForm(this)
    this.resetPwdForm = this.$form.createForm(this)
  }

  private validateResetPwd(rule, value, callback) {
    const form = this.resetPwdForm
    const exp = /^(?=.*\d)(?=.*[a-zA-Z]).{8,20}$/
    if (exp.test(value)) {
      if (value) {
        form.validateFields(['repeatPassword'], { force: true })
      }
    } else {
      callback(this.$t('personal.passwordUnLaw'))
    }
    callback()
  }

  private validateResetRepeatPwd(rule, value, callback) {
    const form = this.resetPwdForm
    if (value && value !== form.getFieldValue('password')) {
      callback(this.$t('login.inconsistentPwd'))
    } else {
      callback()
    }
  }

  private changeLocale(language): void {
    const currentLocale = this.$i18n.locale

    if (currentLocale === language) return
    this.$i18n.locale = language
    this.$store.commit('changeLocale', language)
    setLocale(language)
    this.language = this.languages[language]
  }

  private getAreaCodeList(): void {
    DropDownController.getCountryList()
      .then(res => {
        this.areaCodeList = Array.from(new Set(res.data.map(item => item.extraValue)))
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getVerCode(): void {
    const form = this.verCodeLoginForm
    const mobile = form.getFieldValue('mobile')
    const areaCode = form.getFieldValue('areaCode')
    if (mobile) {
      this.verCodeLoading = true
      LoginController.sendMobileVerifyCode(areaCode, mobile)
        .then(res => {
          if (res.data.success) {
            this.$message.success(this.$tc('common.sendVerCodeSuccess'))
            this.verCodeTimer = 120
            this.resendCountDown()
            const mobile = form.getFieldValue('mobile')
            form.setFields({
              ['mobile']: {
                value: mobile,
              },
            })
          } else {
            const mobile = form.getFieldValue('mobile')
            form.setFields({
              ['mobile']: {
                value: mobile,
                errors: [{ message: res.data.resMsg, field: 'mobile' }],
              },
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (this.verCodeLoading = false))
    } else {
      form.setFields({
        ['mobile']: {
          value: mobile,
          errors: [{ message: this.$t('login.enterMobile'), field: 'mobile' }],
        },
      })
    }
  }

  private getResetVerCode(): void {
    const form = this.resetPwdForm
    const mobile = form.getFieldValue('mobile')
    if (mobile) {
      this.resetVerCodeLoading = true
      LoginController.sendVerifyCode(mobile)
        .then(res => {
          if (res.data.success) {
            this.$message.success(this.$tc('common.sendVerCodeSuccess'))
            this.resetVerCodeTimer = 60
            this.resetMailTips = res.data.resMsg
            this.resetResendCountDown()
            const mobile = form.getFieldValue('mobile')
            form.setFields({
              ['mobile']: {
                value: mobile,
              },
            })
          } else {
            const mobile = form.getFieldValue('mobile')
            form.setFields({
              ['mobile']: {
                value: mobile,
                errors: [{ message: res.data.resMsg, field: 'mobile' }],
              },
            })
          }
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => (this.resetVerCodeLoading = false))
    } else {
      form.setFields({
        ['mobile']: {
          value: mobile,
          errors: [{ message: this.$t('login.enterMobile'), field: 'mobile' }],
        },
      })
    }
  }

  private resetResendCountDown(): void {
    this.resetTimer = setInterval(() => {
      if (this.resetVerCodeTimer === 0) {
        clearInterval(this.resetTimer)
        this.resetTimer = null
      } else {
        this.resetVerCodeTimer = this.resetVerCodeTimer - 1
      }
    }, 1000)
  }

  private resendCountDown(): void {
    this.timer = setInterval(() => {
      if (this.verCodeTimer === 0) {
        clearInterval(this.timer)
        this.timer = null
      } else {
        this.verCodeTimer = this.verCodeTimer - 1
      }
    }, 1000)
  }

  private mobileSubmit(): void {
    this.loginLoading = true
    const form = this.passwordLoginForm
    form.validateFields((err: any, values: any) => {
      if (!err) {
        const account = excludeSpecial(values.mobile)
        const password = excludeSpecial(values.password)

        // LoginController.login({ mobile, password })
        LoginController.unifyLogin({ account, password })
          .then(res => {
            if (res.data.success) {
              this.baidu.event(this.school.name, '正确登录', this.isPc ? 'PC' : '手机')

              const token = res.data.token
              setToken(token, true)
              // if(this.isPc){
              //   this.$router.push({ name: 'main' }).catch(err => {})
              // } else {
              //   let ua = navigator.userAgent.toLowerCase()
              //   let isChat = ua.indexOf('micromessenger') !== -1
              //   const schoolId = (getSchoolInfo() || {}).schoolId
              //   const appId = this.$store.state.appId[schoolId]
              //   if (!isChat || !appId || process.env.VUE_APP_ENV === 'DEV') {
              //     this.$router.push({ name: 'main' }).catch(err => {})
              //     return
              //   }
              //   const href = `${window.location.origin}/mobile`
              //   const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(href)}&response_type=code&scope=snsapi_base#wechat_redirect`
              //   window.location.href = url
              // }

              this.$router.push({ name: 'main' }).catch(err => {})
            } else {
              this.baidu.event(this.school.name, '错误登录', this.isPc ? 'PC' : '手机')
              const mobile = form.getFieldValue('mobile')
              form.setFields({
                ['mobile']: {
                  value: mobile,
                  errors: [{ message: this.$t(`errorCode.${res.data.resCode}`), field: 'mobile' }],
                },
              })
            }
          })
          .catch(err => {
            console.error(err)
            this.baidu.event(this.school.name, '错误登录', this.isPc ? 'PC' : '手机')
          })
          .finally(() => {
            this.loginLoading = false
          })
      } else {
        this.loginLoading = false
      }
    })
  }

  private resetPwd(): void {
    this.resetPwdVisible = true
    const mobile = this.passwordLoginForm.getFieldValue('mobile')
    this.$nextTick(() => {
      this.resetPwdForm.setFieldsValue({ ['mobile']: mobile })
    })
  }

  private confirmReset(): void {
    this.resetLoading = true
    const form = this.resetPwdForm
    form.validateFields((err: any, values: any) => {
      if (!err) {
        const { password, repeatPassword, mobile, ...data } = values
        LoginController.resetPassword({ ...data, newPassword: password, mobile: mobile.trim() })
          .then(res => {
            if (res.data.success) {
              this.$message.success(this.$tc('common.setPasswordSuccess'))
              this.resetPwdVisible = false
              const token = res.data.token
              setToken(token, true)
              window.location.reload()
              // this.$router.push({name: 'main'}).catch(err => console.log(err))
            } else {
              const mobile = form.getFieldValue('mobile')
              form.setFields({
                ['mobile']: {
                  value: mobile,
                  errors: [{ message: this.$t(`errorCode.${res.data.resCode}`), field: 'mobile' }],
                },
              })
            }
          })
          .catch(err => {
            console.error(err)
          })
          .finally(() => {
            this.resetLoading = false
          })
      } else {
        this.resetLoading = false
      }
    })
  }

  private cancelReset(): void {
    this.resetPwdForm.resetFields()
    clearTimeout(this.resetTimer)
    this.resetVerCodeLoading = false
    this.resetVerCodeTimer = 0
    this.resetMailTips = ''
  }

  private verCodeSubmit(): void {
    this.loginLoading = true
    const form = this.verCodeLoginForm
    form.validateFields((err: any, values: any) => {
      if (!err) {
        const account = excludeSpecial(values.mobile)
        const vcode = excludeSpecial(values.vcode)
        const areaCode = excludeSpecial(values.areaCode)

        // LoginController.login({mobile, vcode, areaCode})
        LoginController.unifyLogin({ account, vcode, areaCode })
          .then(res => {
            if (res.data.success) {
              this.baidu.event(this.school.name, '正确登录', this.isPc ? 'PC' : '手机')

              const token = res.data.token
              setToken(token, true)
              if (this.isPc) {
                this.$router.push({ name: 'main' }).catch(err => {})
              } else {
                let ua = navigator.userAgent.toLowerCase()
                let isChat = ua.indexOf('micromessenger') !== -1
                const schoolId = (getSchoolInfo() || {}).schoolId
                const appId = this.$store.state.appId[schoolId]
                if (!isChat || !appId || process.env.VUE_APP_ENV === 'DEV') {
                  this.$router.push({ name: 'main' }).catch(err => {})
                  return
                }
                const href = `${window.location.origin}/mobile`
                const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${encodeURIComponent(
                  href
                )}&response_type=code&scope=snsapi_base#wechat_redirect`
                window.location.href = url
              }
            } else {
              this.baidu.event(this.school.name, '错误登录', this.isPc ? 'PC' : '手机')

              const mobile = form.getFieldValue('mobile')
              form.setFields({
                ['mobile']: {
                  value: mobile,
                  errors: [{ message: this.$t(`errorCode.${res.data.resCode}`), field: 'mobile' }],
                },
              })
            }
          })
          .catch(err => {
            console.error(err)
            this.baidu.event(this.school.name, '错误登录', this.isPc ? 'PC' : '手机')
          })
          .finally(() => {
            this.loginLoading = false
          })
      } else {
        this.loginLoading = false
      }
    })
  }
}
