











import { Component, Vue, Watch } from 'vue-property-decorator'
import {
  CurriculumController,
  StudentController,
  DropDownController,
} from '@/services/request.service'
import moment, { Moment } from 'moment'
import curriculumForExport from '@/components/curriculumForExport.vue'
import flattenDeep from 'lodash/flattenDeep'

@Component({
  components: {
    curriculumForExport,
  },
})
export default class ExportCurriculum extends Vue {
  private startTime = moment().startOf('isoWeek')
  private endTime = moment().endOf('isoWeek')

  private yearKey: number = 0
  private yearList: Array<any> = []

  private studentId: any = 0
  private type: any
  private classroomId: any
  private curriculumData: any = {
    classArranges: [],
    classPeriods: [],
    total: 0,
  }
  private studentInfo: any = {}

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    this.type = to.params.type
    this.startTime = moment(Number(to.params.time.split('-')[0]))
    this.endTime = moment(Number(to.params.time.split('-')[1].split('&')[0]))
    this.studentId = to.params.studentId
    this.yearKey = Number(to.params.key)
    this.getMyCurriculum()
  }

  private getMyCurriculum(): void {
    const start = this.startTime.valueOf()
    const end = this.endTime.valueOf()
    Promise.all([
      CurriculumController.getClassArrangesByStudentAndWeek(
        this.studentId,
        start,
        end,
        this.yearKey
      ),
      StudentController.getStudentBriefInfo(this.studentId),
      DropDownController.getSchoolYearRuleList(),
    ])
      .then(res => {
        const { classArranges, classPeriods, dayOfArranged } = res[0].data
        let total = 0
        try {
          total = Object.keys(classArranges)
            .map(
              key =>
                classArranges[key] &&
                Object.keys(classArranges[key])
                  .map(c => classArranges[key][c])
                  .filter(item => item.type !== 'periodArranges').length
            )
            .filter(f => f || f === 0)
            .reduce((total, current) => total + current)
        } catch (err) {
          console.log(err)
        }
        // for(let key in classArranges){
        //   const keys = Object.keys(classArranges[key])
        //   total += Object.keys(classArranges[key]).length
        // }
        this.curriculumData = {
          classArranges,
          classPeriods,
          dayOfArranged,
          total,
        }
        this.studentInfo = res[1].data
        this.yearList = res[2].data
      })
      .catch(err => {
        console.error(err)
      })
  }
}
