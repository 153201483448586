/*
 * @Author: Chu Yifei
 * @LastEditors: Chu Yifei
 */
export const differSchoolId = [2, 5, 7, 8, 9]
export const staticData = {
  helpDocUrl: 'https://www.yuque.com/docs/share/006fdd19-231e-4503-9826-692ef8c59fd7?#',
  feedbackMail: 'intschoolsupport@huaercollegiate.com',
}

export default {
  differSchoolId,
  staticData,
}
