













































































































































































import { Component, Inject, Vue, Watch } from 'vue-property-decorator'
import {
  setLocale,
  removeToken,
  removeSchoolInfo,
  getSchoolInfo,
  getLocale,
  setUserAuth,
  setCurrentStudent,
  getIntroShow,
  setIntroShow,
  getGuideViewStatus,
} from '@/utils/utils'
import { RouteRecord } from 'vue-router'
import {
  LoginController,
  ParentController,
  StudentController,
  MessageController,
  SemesterController,
  DropDownController,
} from '@/services/request.service'
import PCMenu from '@/components/PCMenu.vue'
// import PhoneMenu from '@/components/'
import PadMenu from '@/components/PadMenu.vue'
import MenuLevel3 from '@/components/MenuLevel3.vue'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'
import Introduction from '@/components/Introduction.vue'
import debounce from 'lodash/debounce'
import { versionUpdateTip } from '@/components/version'
import Guide from '@/components/Guide.vue'
import { staticData } from '@/constant/config'
import SystemUpdate from '@/components/SystemUpdate.vue'

@Component({
  components: {
    PCMenu,
    PadMenu,
    MenuLevel3,
    Introduction,
    Guide,
  },
})
export default class Main extends Vue {
  @Inject() private readonly reloadPage!: any
  private schoolVisible = false
  private selectedSchool: any = {
    schoolId: 0,
    name: '',
  }
  private selectedStudent: any = {
    studentId: 0,
    name: '',
  }
  private feedBackVis = false
  private haveSystemMessage = false
  private unreadData: any = {}
  private unreadSysData: any = {}
  private unReadTeach: any = {}
  private sMessageNum = 0
  private mail = staticData.feedbackMail || ''
  private selectedPage = ''
  private schoolId: any = (getSchoolInfo() || {}).schoolId
  private introVis: any = false
  private guideVis: any = false
  private guideType: any = ''
  private resizeEvent: any = null
  private newMesInterval: any = 5
  private mesInfo: any = null

  private get locale(): string {
    return this.$store.state.locale
  }

  private get contentHeaderShow(): boolean {
    const route = this.routes[this.routes.length - 1]
    if (route.meta.noChild) {
      return false
    } else {
      return true
    }
  }

  private get studentListShow(): boolean {
    const route = this.routes[this.routes.length - 1]
    if (route.meta.showStudents) {
      return true
    } else {
      return false
    }
  }

  private get routes(): Array<any> {
    const router = this.$route as any
    return router.matched.filter((item: RouteRecord) => item.meta && item.meta.routerName)
  }

  private get isPad(): boolean {
    // return document.body.clientWidth < 1440
    return navigator.userAgent.indexOf('iPad') !== -1
  }

  private get students(): any {
    return this.$store.state.students
  }

  private get currentStudent(): number {
    return this.$store.state.currentStudent || {}
  }

  private get userName(): any {
    const { isTeacher, username, teacherEnName, teacherName } = this.$store.state.userInfo
    const realTeacherName =
      this.locale === 'zh' ? teacherName || teacherEnName : teacherEnName || teacherName
    return isTeacher ? `${realTeacherName}-${username}` : username
  }

  @Watch('$route', { immediate: true })
  private onRouteChanged(to: any, from: any) {
    this.getMessageStatus()
    this.getSystemMessage()
    this.getTeachStatus()
    const schoolInfo = getSchoolInfo()
    if (schoolInfo) {
      this.selectedSchool = schoolInfo
    } else {
      this.logout()
    }
    this.selectedPage = to.meta.routerName
  }

  private created(): void {
    this.getUserInfo()
    this.getSchoolList()
    this.getCurrentSchoolYear()
    // versionUpdateTip(this.$createElement, () => {
    const introShow = getIntroShow() || {}
    if (!introShow.pc) {
      // if (this.schoolId !== 8) {
      //   this.introVis = true
      // } else {
      //   this.guideVis = true
      //   this.guideType = 'introduction'
      // }
      this.introVis = true
    }
    // })

    const locale = getLocale()
    this.$store.commit('changeLocale', locale)
  }

  private checkSystemStatus(): void {
    MessageController.sysMessageNotice().then(res => {
      let { mesId, message, enMessage } = res.data
      let systemMesId = localStorage.getItem('systemMesId')
      if (mesId === null || systemMesId === mesId?.toString()) return
      if (this.mesInfo) this.mesInfo()
      let systemUpdate = this.$createElement(SystemUpdate, {
        props: {
          tips: message,
          enTips: enMessage,
        },
        on: {
          close: () => {
            localStorage.setItem('systemMesId', mesId.toString())
            this.mesInfo()
            this.mesInfo = null
          },
        },
      })
      this.mesInfo = this.$message.warning(systemUpdate, 0)
    })
  }

  private getUserInfo(): void {
    ParentController.getUserInfo()
      .then(res => {
        this.$store.commit('setUserInfo', {
          username: (res.data.enName + ' ' + res.data.name).trim(),
          memberId: res.data.parentId,
          ...res.data,
        })
      })
      .catch(err => {
        console.error(err)
      })
      .finally(() => {
        if (this.$store.state.refresh) {
          this.$store.commit('cleanRefresh')
          this.$router.replace({ name: 'main' }).catch(err => {})
        }
      })
  }

  private getCurrentSchoolYear(): void {
    SemesterController.getCurrentSchoolYear()
      .then(res => {
        let currentSchoolYear = {
          ...res.data,
          startTime: res.data.startTime,
          endTime: res.data.endTime,
        }
        this.$store.commit('setCurrentSchoolYear', currentSchoolYear)
      })
      .catch(err => console.error(err))
  }

  private getSchoolList(): void {
    this.selectedSchool = getSchoolInfo()
  }

  private getMessageStatus(): void {
    Promise.all([MessageController.hasUnreadMessage(), MessageController.hasUnreadSystemMessage()])
      .then(res => {
        this.unreadData = res[0].data
        this.unreadSysData = res[1].data
        this.$store.commit('setUnreadData', this.unreadData)
        this.$store.commit('setUnreadSysData', this.unreadSysData)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getTeachStatus(): void {
    DropDownController.unReadTeach()
      .then(res => {
        let data = res.data
        let temp = {}
        for (let item of data) {
          temp[item.studentId] = item.isRead
        }
        this.unReadTeach = temp
        this.$store.commit('setUnReadTeach', this.unReadTeach)
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getNewMessageNum(): void {
    MessageController.unReadMessageCount(this.newMesInterval).then(res => {
      const newMes = res.data as any
      let flag = false
      Object.keys(newMes).forEach(key => {
        if (newMes[key]) flag = true
      })
      if (flag) {
        this.$notification.info({
          message: this.$tc('message.newMessageNotice'),
          description:
            this.$t('message.receivedMessage') +
            (newMes.system
              ? '\n' + this.$tc('message.systemMessage', newMes.system, { num: newMes.system })
              : '') +
            (newMes.normal
              ? '\n' + this.$tc('message.normalMessage', newMes.normal, { num: newMes.normal })
              : ''),
          style: {
            whiteSpace: 'pre',
          },
          class: 'new-mes-info',
          duration: 30,
          onClick: () => {
            this.toInbox()
          },
        })
      }
    })
  }

  private getSystemMessage(): void {
    MessageController.hasUnreadSystem()
      .then(res => {
        let systemMessage = {
          leave: res.data,
        }
        let count = 0
        for (let k in systemMessage) {
          count += systemMessage[k]
        }
        this.$store.commit('setSystemMessageNum', systemMessage)
        this.sMessageNum = count
      })
      .catch(err => console.log(err))
  }

  private mounted(): void {
    this.resizeEvent = debounce(this.onResize, 100)
    let ratio = window.innerWidth / 1920
    this.$store.commit('setRatio', ratio)

    // 定时轮询消息状态
    let timer = setInterval(() => {
      this.getMessageStatus()
      this.getTeachStatus()
    }, this.newMesInterval * 60 * 1000)

    // 定时轮询新消息
    let newMesTimer = setInterval(() => {
      this.getNewMessageNum()
    }, this.newMesInterval * 60 * 1000)

    // 定时轮询系统推送消息
    this.checkSystemStatus()
    let checkTimer = setInterval(() => {
      this.checkSystemStatus()
    }, 60 * 60 * 1000)

    window.addEventListener('resize', this.resizeEvent)
    this.$once('hook:beforeDestroy', () => {
      if (this.mesInfo) this.mesInfo()
      clearInterval(timer)
      clearInterval(newMesTimer)
      clearInterval(checkTimer)
      window.removeEventListener('resize', this.resizeEvent)
    })
  }

  private onResize(): void {
    let ratio = window.innerWidth / 1920
    this.$store.commit('setRatio', ratio)
  }

  private changeStudent({ key }): void {
    const selectedStudent = this.students.filter(student => student.studentId === key)[0]
    this.selectedStudent = selectedStudent
    this.$store.commit('refresh')
    setCurrentStudent(key)
    this.$store.commit('setCurrentStudent', selectedStudent)
    this.$store.commit('changeForceBack', true)
    this.$store.dispatch('refreshRoutes')
    this.reloadPage()
  }

  private viewSystemMessage(): void {
    this.$router.push({ name: 'systemMessage' }).catch(err => {})
  }

  private openPersonal(): void {
    this.$router.push({ name: 'personal' }).catch(err => {})
  }

  private changeLocale(lan): void {
    const currentLocale = this.$i18n.locale
    if (currentLocale === lan) return
    this.$i18n.locale = lan
    this.$store.commit('changeLocale', lan)
    setLocale(lan)
  }

  private clickTopMenu({ key }: { key: string }): void {
    this.$router.push({ name: key }).catch(err => {})
  }

  private moveToPrevPage(): void {
    this.$router.back()
  }

  private logout(): void {
    removeToken()
    removeSchoolInfo()
    this.$store.commit('changeForceBack', true)
    this.$store.dispatch('refreshRoutes')
    this.$router.push({ name: 'login' }).catch(err => {})
  }

  private onIntroChange(vis): void {
    if (!vis) {
      let routeName = this.$route.name as any
      let matchRoute = {
        parentsMeeting: 'book',
        takeLeave: 'takeLeave',
      }
      if (!Object.keys(matchRoute).includes(routeName)) return
      let status = getGuideViewStatus()
      this.guideType = matchRoute[routeName]
      if (!status[`${this.guideType}PC`]) {
        this.guideVis = true
      }
    }
  }

  private toHelp(): void {
    const url = staticData.helpDocUrl
    window.open(url)
  }

  private toPage({ key }): void {
    this.$router.push({ name: key }).catch(err => {})
  }

  private toInbox(): void {
    if (this.$route.matched.find(item => item.name === 'inbox')) return
    this.$router.push({ name: 'inbox', params: { mesType: 'normal' } })
  }

  @Watch('$store.state.unReadTeach', { immediate: true, deep: true })
  private refreshTeachRead(val): void {
    this.unReadTeach = val
  }
}
